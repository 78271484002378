import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isPhoneNumber } from '../../general/helpers/isPhoneNumber'
import { isYmdDate } from '../../general/helpers/isYmdDate'

export const UserDetailValidator = z.object({
  id: z.string().refine(isIntegerString), // user id
  email: z.union([z.string().max(0), z.string().email()]),
  mobile: z.union([z.string().max(0), z.string().refine(isPhoneNumber)]),
  phone: z.union([z.string().max(0), z.string().refine(isPhoneNumber)]),
  street: z.string().max(255),
  zip: z.string().max(255),
  city: z.string().max(255),
  dateOfBirth: z.string().refine(isYmdDate).nullable(),
  notes: z.string(),
  department: z.string(),
})
