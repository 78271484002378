import { Model } from 'components/Form/Model'
import * as React from 'react'
import { IInternalResidenceFormModel } from '../interfaces/IInternalResidenceFormModel'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { InputDate } from 'components/Form/components/InputDate'
import { Button } from 'components/Form/components/Button'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Message } from 'components/Message'
import { dayjs } from 'helpers/dayjs'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { hermes } from '@byll/hermes'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import {
  babyOptions,
  billingStatusOptions,
  foodOptions,
} from './InternalResidenceFormStep3'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { getReason } from '../helpers/getReason'
import { ABRECHNUNGSMODUS_AWUM } from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { InputDocument } from 'components/Form/components/InputDocument'
import { getZimmerkategorieOptions } from 'contracts/accommodations/helpers/getZimmerkategorieOptions'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import {
  IEnabledInternalResidenceFormFields,
  getEnabledFields,
} from '../helpers/getEnabledFields'
import { INSTANCE_IDS } from 'contracts/general/helpers/instanceIds'

interface Props {
  model: Model<IInternalResidenceFormModel>
  onClose: () => void
  setStep: (step: 'rooms' | 'form' | 'beds') => void
  color: 'warning' | 'secondary'
  bookings?: IBooking[]
  autoResponsibility?: boolean
  checkInPermission: 0 | 1 | 2
}

const responsibilityBeginReasons: InputSelectOption[] = [
  { value: '', label: '' },
  { value: 'Zugang', label: 'Zugang' },
  { value: 'Verlegung', label: 'Verlegung' },
  {
    value: 'Wiederaufnahme nach JVA Aufenthalt',
    label: 'Wiederaufnahme nach JVA Aufenthalt',
  },
  { value: 'Wiederaufnahme nach Krankenhaus', label: 'Wiederaufnahme nach Krankenhaus' },
]

@observer
export class InternalResidenceFormStep1 extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly enabled: IEnabledInternalResidenceFormFields
  @observable private readOnly = true // show save button if readOnly = false
  @observable private saving = false
  @observable private error: string | null = null
  private readonly zimmerkategorieOptions: InputSelectOption[]
  private readonly disposers: Disposer[] = []
  private readonly showReason: boolean

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.enabled = getEnabledFields(
      props.model.values.isNew,
      props.model.values.type,
      props.model.values.buildingId,
      props.checkInPermission,
      context.permissions,
    )
    this.error = this.enabled.error
    this.readOnly = !!this.enabled.error

    this.zimmerkategorieOptions = [
      { value: '', label: '' },
      ...getZimmerkategorieOptions(context.instance.id).map((value) => ({
        value,
        label: value,
      })),
    ]
    this.showReason =
      props.model.values.type === 'internal-residence' &&
      (context.instance.id === INSTANCE_IDS.testapp ||
        context.instance.id === INSTANCE_IDS.app)
    makeObservable(this)
  }

  componentDidMount(): void {
    if (this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM) {
      this.disposers.push(
        reaction(
          () => this.props.model.values.documentId,
          (documentId) => {
            if (this.props.model.values.billingStatus === 'Aufnahmeverfügung fehlt') {
              this.props.model.values.billingAvDate = documentId
                ? dayjs().format('YYYY-MM-DD')
                : null
            } else {
              this.props.model.values.billingDateOfNoticeCreation = documentId
                ? dayjs().format('YYYY-MM-DD')
                : null
            }
          },
        ),
      )
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private toggleHasEnd = () => {
    this.props.model.values.hasEnd = !this.props.model.values.hasEnd
    this.props.model.values.endDate = null
    this.props.model.values.endTime = ''
  }

  @action
  private next = () => {
    if (!this.props.model.isValid()) {
      this.props.model.setFocusToLeftTopmostInvalidField()
      return
    }
    const beginAt = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const endAt = this.props.model.values.hasEnd
      ? dayjs(`${this.props.model.values.endDate} ${this.props.model.values.endTime}`)
      : null
    if (endAt && beginAt.isSameOrAfter(endAt)) {
      this.error = 'Der Anfangszeitpunkt muss vor dem Endzeitpunkt liegen.'
      return
    }
    if (
      !this.context.permissions.resident_futureCheckIn &&
      dayjs().add(1, 'day').isBefore(beginAt) &&
      this.props.model.values.isNew
    ) {
      this.error = 'Anfangszeitpunkt darf nicht mehr als 24h in der Zukunft liegen.'
      return
    }

    if (!this.props.model.values.isNew) {
      void this.save()
    } else {
      // Detect responsibility
      const bookings = this.props.bookings || []
      for (const booking of bookings) {
        if (
          booking.type !== 'responsibility-begin' &&
          booking.type !== 'responsibility-end'
        ) {
          continue
        }
        if (dayjs(booking.beginAt).isAfter(beginAt)) {
          continue
        }
        if (booking.type === 'responsibility-begin') {
          runInAction(() => (this.props.model.values.compoundId = booking.compoundId!))
        }
        break
      }
      if (this.props.autoResponsibility) {
        this.props.setStep('beds')
      } else {
        this.props.setStep('rooms')
      }
    }
  }

  private save = async () => {
    try {
      const data: Partial<IBooking> & { checkOut?: boolean } = {
        isBaby: this.props.model.values.isBaby,
        comment: this.props.model.values.comment,
        beginAt: dayjs(
          `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
        ).toISOString(),
        endAt: this.props.model.values.hasEnd
          ? dayjs(
              `${this.props.model.values.endDate} ${this.props.model.values.endTime}`,
            ).toISOString()
          : null,
        reason: getReason(this.props.model.values, this.showReason),
        documentId: this.props.model.values.documentId,
        extra: {
          food: this.props.model.values.food || '',
          rast: this.props.model.values.hasRast,
          admissionFrom: this.props.model.values.admissionFrom,
          billing:
            this.props.model.values.type !== 'internal-residence'
              ? undefined
              : {
                  status: this.props.model.values.billingStatus,
                  category: this.props.model.values.billingCategory,
                  dateOfNoticeCreation:
                    this.props.model.values.billingDateOfNoticeCreation,
                  avDate: this.props.model.values.billingAvDate,
                },
        },
      }
      runInAction(() => (this.saving = true))
      await hermes.patch(
        `/api/${this.context.instance.id}/bookings/${this.props.model.values.id}`,
        data,
      )
      this.props.onClose()
    } catch (e: any) {
      runInAction(() => {
        this.saving = false
        this.error = e?.message || 'Die Belegung konnte nicht gespeichert werden.'
      })
    }
  }

  render() {
    const model = this.props.model
    return (
      <>
        {this.error && (
          <Message className='my-3' color='danger'>
            {this.error}
          </Message>
        )}

        <div className='relative grid grid-cols-4 gap-4 mt-4' id={model.id}>
          <InputDate
            disabled={this.saving || !this.enabled.all}
            model={model}
            name='beginDate'
            label='Ab'
            placeholder='DD.MM.JJJJ'
          />
          <InputText
            disabled={this.saving || !this.enabled.all}
            model={model}
            name='beginTime'
            tooltip='Uhrzeit benötigt'
            placeholder='hh:mm'
          />
          <div className='relative'>
            <InputDate
              disabled={this.saving || !this.enabled.all || !model.values.hasEnd}
              model={model}
              name='endDate'
              label='      Bis'
              placeholder='DD.MM.JJJJ'
            />
            <input
              type='checkbox'
              disabled={this.saving || !this.enabled.all}
              checked={model.values.hasEnd}
              onChange={this.toggleHasEnd}
              style={{
                position: 'absolute',
                top: -8,
                left: 12,
                zIndex: 1,
                transform: 'scale(0.9)',
                borderRadius: '4px',
              }}
              className='border-gray-300 text-indigo-600'
            />
          </div>
          <InputText
            disabled={this.saving || !this.enabled.all || !model.values.hasEnd}
            tooltip='Uhrzeit benötigt'
            model={model}
            name='endTime'
            placeholder='hh:mm'
          />

          {!!model.values.label && (
            <>
              <InputText
                disabled
                model={model}
                name='label'
                label='Raum'
                className={model.values.bed ? 'col-span-3' : 'col-span-4'}
              />
              {!!model.values.bed && (
                <InputText disabled model={model} name='bed' label='Bett' />
              )}
            </>
          )}

          {this.context.permissions.host_lfgb &&
            this.props.model.values.type === 'internal-residence' &&
            !this.props.model.values.isNew && (
              <>
                <InputSelect
                  model={this.props.model}
                  name='food'
                  label='Verpflegung'
                  className='col-span-3'
                  options={foodOptions}
                />
                <InputSelect
                  model={this.props.model}
                  name='isBaby'
                  label='Babybett'
                  options={babyOptions}
                />
              </>
            )}

          {this.showReason && (
            <InputSelect
              disabled={this.saving || !this.enabled.all}
              model={model}
              name='reason'
              label='Grund'
              className={model.values.reason === 'Zugang' ? 'col-span-2' : 'col-span-4'}
              options={responsibilityBeginReasons}
            />
          )}
          {this.showReason && model.values.reason === 'Zugang' && (
            <InputText
              disabled={this.saving || !this.enabled.all}
              model={model}
              name='admissionFrom'
              label='Zugang von'
              className='col-span-2'
            />
          )}

          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_AWUM &&
            this.props.model.values.id && (
              <>
                {this.context.permissions.booking_feeStatus > 0 && (
                  <InputSelect
                    disabled={this.saving || !this.enabled.billingStatus}
                    model={model}
                    name='billingStatus'
                    label='Gebührenstatus'
                    className='col-span-2'
                    options={billingStatusOptions}
                  />
                )}
                {this.context.permissions.booking_feeCategory > 0 && (
                  <InputSelect
                    disabled={this.saving || !this.enabled.billingCategory}
                    model={model}
                    name='billingCategory'
                    label='Zimmerkategorie'
                    className='col-span-2'
                    options={this.zimmerkategorieOptions}
                  />
                )}
                {this.context.permissions.booking_feeNotice > 0 && (
                  <InputDocument
                    scope='booking'
                    disabled={this.saving || !this.enabled.documentId}
                    model={model}
                    name='documentId'
                    label={
                      model.values.billingStatus === 'Aufnahmeverfügung fehlt'
                        ? 'Aufnahmeverfügung'
                        : 'Gebührenbescheid'
                    }
                    className='col-span-2'
                    preview
                  />
                )}
                {this.context.permissions.booking_feeDateOfNoticeCreation > 0 &&
                  model.values.billingStatus !== 'Aufnahmeverfügung fehlt' && (
                    <InputDate
                      disabled={this.saving || !this.enabled.billingDateOfNoticeCreation}
                      model={model}
                      name='billingDateOfNoticeCreation'
                      label='Erstellt am'
                      placeholder='DD.MM.JJJJ'
                      className='col-span-2'
                    />
                  )}
                {this.context.permissions.booking_feeDateOfNoticeCreation > 0 &&
                  model.values.billingStatus === 'Aufnahmeverfügung fehlt' && (
                    <InputDate
                      disabled={this.saving || !this.enabled.billingAvDate}
                      model={model}
                      name='billingAvDate'
                      label='AV Datum'
                      placeholder='DD.MM.JJJJ'
                      className='col-span-2'
                    />
                  )}

                {/* This is just a placeholder if there is an uneven number of fields visible */}
                {((this.context.permissions.booking_feeStatus > 0 ? 1 : 0) +
                  (this.context.permissions.booking_feeCategory > 0 ? 1 : 0) +
                  (this.context.permissions.booking_feeNotice > 0 ? 1 : 0) +
                  (this.context.permissions.booking_feeDateOfNoticeCreation > 0
                    ? 1
                    : 0)) %
                  2 ===
                  1 && (
                  <InputText
                    disabled
                    model={model}
                    name='placeholder'
                    label=''
                    className='col-span-2'
                  />
                )}
              </>
            )}

          <InputTextarea
            disabled={this.saving || !this.enabled.all}
            model={model}
            name='comment'
            label='Notiz'
            rows={3}
            className='col-span-4'
          />

          <div className='col-span-4 text-right flex'>
            <div className='flex-auto flex'>
              {/* hasHouseBan and rast takes up too much space together. Since House ban is not explicitly used on systems with rast, is is omitted for layout reasons. But if there is a way to fit in both, it doesn't need to be omitted by resident_booking_showRast */}
              {!this.context.permissions.resident_booking_showRast &&
                this.props.model.values.type === 'internal-residence' &&
                !this.showReason && (
                  <InputCheckbox
                    disabled={this.saving || !this.enabled.all}
                    model={this.props.model}
                    name='hasHouseBan'
                    label='Anschließend Hausverbot'
                  />
                )}
              {this.context.permissions.resident_booking_showRast &&
                this.props.model.values.type === 'internal-residence' && (
                  <InputCheckbox
                    disabled={this.saving || !this.enabled.all}
                    model={this.props.model}
                    name='hasRast'
                    label='RAST Fall'
                  />
                )}
            </div>
            <Button color='secondary' outline onClick={this.props.onClose}>
              {this.readOnly ? 'Schließen' : 'Abbrechen'}
            </Button>
            {!this.readOnly && (
              <Button color={this.props.color} className='ml-2' onClick={this.next}>
                {model.values.isNew ? 'Weiter' : 'Speichern'}
              </Button>
            )}
          </div>
        </div>
        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
