import { Collection, hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { ConflictError, NotAuthorizedError } from 'contracts/errors/HermesErrors'
import {
  IBooking,
  INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE,
  INFO_BOOKING_REASON_END_OF_STAY,
} from 'contracts/residents/interfaces/IBooking'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { dayjs } from 'helpers/dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { ResponsibilityEndModel } from '../../../ResponsibilityEndForm'

interface Props {
  resident: IResident
  model: Model<ResponsibilityEndModel>
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  setStep: (step: 'form' | 'creating') => void
}

const types = {
  success: {
    parent: 'bg-green-50 text-green-600 border border-green-500 mt-6 rounded-md p-3',
    message: 'text-green-500 text-sm ml-2',
  },
  warning: {
    parent: 'bg-yellow-50 text-yellow-500 border border-yellow-500 mt-6 rounded-md p-3',
    message: 'text-yellow-400 text-sm ml-2',
  },
  error: {
    parent: 'bg-red-50 text-red-500 border border-red-500 mt-6 rounded-md p-3',
    message: 'text-red-400 text-sm ml-2',
  },
}

interface IMessage {
  resident: { id: string; firstName: string; lastName: string }
  message: string
  type: 'success' | 'warning' | 'error'
}

@observer
export class EndOfStayStep2Creating extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private messages: IMessage[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.create()
  }

  private create = async () => {
    const begin = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const endOfStayData: Partial<IBooking> = {
      instanceId: this.context.instance.id,
      type: 'info',
      compoundId: null,
      // residentId
      roomId: null,
      bed: null,
      reason: INFO_BOOKING_REASON_END_OF_STAY,
      comment: this.props.model.values.comment,
      beginAt: begin.toISOString(),
      endAt: begin.add(1, 'minute').toISOString(),
      extra: {
        ...this.props.model.values.extra,
        infoReason: this.props.model.values.reason,
      },
    }
    const endOfBillingData: Partial<IBooking> = {
      instanceId: this.context.instance.id,
      type: 'info',
      compoundId: null,
      // residentId
      roomId: null,
      bed: null,
      reason: INFO_BOOKING_REASON_AWUM_GEBUEHRENPFLICHTENDE,
      comment: '',
      beginAt: begin.add(-1, 'minute').toISOString(),
      endAt: begin.toISOString(),
      documentId: null,
      extra: {
        billing: {
          status: '',
          category: '',
          dateOfNoticeCreation: null,
          avDate: null,
        },
      },
    }
    const messages: IMessage[] = []

    for (const member of this.props.members.resources || []) {
      if (!member.data || member.data.deletedAt) {
        continue
      }
      if (member.id !== this.props.resident.id && !this.props.model.values.family) {
        continue
      }
      try {
        await hermes.create(`/api/${this.context.instance.id}/bookings`, {
          ...endOfStayData,
          residentId: member.id,
        })
        await hermes.create(`/api/${this.context.instance.id}/bookings`, {
          ...endOfBillingData,
          residentId: member.id,
        })
        messages.push({
          resident: member.data,
          message: 'Aufenthalt erfolgreich beendet.',
          type: 'success',
        })
      } catch (e: any) {
        if (e.id === ConflictError.id) {
          messages.push({
            resident: member.data,
            message: e.message,
            type: e.details?.type || 'error',
          })
        } else if (e.id === NotAuthorizedError.id) {
          messages.push({
            resident: member.data,
            message: 'Sie haben nicht die nötige Berechtigung für diesen Vorgang.',
            type: 'error',
          })
        } else {
          messages.push({
            resident: member.data,
            message: 'Der Aufenthalt konnte nicht beendet werden.',
            type: 'error',
          })
        }
      }
    }

    runInAction(() => (this.messages = messages))
  }

  render() {
    return (
      <div className='min-h-[242px] flex flex-col'>
        {!this.messages && <Spinner />}
        {this.messages && (
          <>
            {this.messages.length === 0 && (
              <Message color='danger' className='mt-6'>
                Beim Beenden des Aufenthaltes ist ein Fehler aufgetreten.
              </Message>
            )}
            {this.messages.map((m) => (
              <div key={m.resident.id} className={types[m.type]?.parent}>
                <span className='text-md'>{`${m.resident.lastName.toUpperCase()}, ${
                  m.resident.firstName
                }`}</span>
                <span className={types[m.type]?.message}>{m.message}</span>
              </div>
            ))}
            <div className='mt-auto border-t border-gray-300 flex-content text-right py-6 -mb-6 z-10 bottom-0 sticky bg-white'>
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
            </div>
          </>
        )}
      </div>
    )
  }
}
