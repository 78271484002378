export const INSTANCE_IDS = {
  dev: '0',
  app: '1',
  testapp: '2',
  stja: '3',
  teststja: '4',
  awum: '5',
  testawum: '6',
  lfg: '7',
  testlfg: '8',
}

export function isJbpInstance(instanceId: string): boolean {
  return (
    INSTANCE_IDS.dev === instanceId ||
    INSTANCE_IDS.app === instanceId ||
    INSTANCE_IDS.testapp === instanceId
  )
}

export function isLfgInstance(instanceId: string): boolean {
  return (
    INSTANCE_IDS.dev === instanceId ||
    INSTANCE_IDS.lfg === instanceId ||
    INSTANCE_IDS.testlfg === instanceId
  )
}

export function isMucInstance(instanceId: string): boolean {
  return (
    INSTANCE_IDS.dev === instanceId ||
    INSTANCE_IDS.awum === instanceId ||
    INSTANCE_IDS.testawum === instanceId ||
    INSTANCE_IDS.stja === instanceId ||
    INSTANCE_IDS.teststja === instanceId
  )
}

export function isAwumInstance(instanceId: string): boolean {
  return (
    INSTANCE_IDS.dev === instanceId ||
    INSTANCE_IDS.awum === instanceId ||
    INSTANCE_IDS.testawum === instanceId
  )
}

export function isStjaInstance(instanceId: string): boolean {
  return (
    INSTANCE_IDS.dev === instanceId ||
    INSTANCE_IDS.stja === instanceId ||
    INSTANCE_IDS.teststja === instanceId
  )
}
