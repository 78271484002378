import { Collection } from '@byll/hermes'
import { Card } from 'components/Card'
import { Spinner } from 'components/Spinner'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Ticket } from './components/Ticket'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { TicketsTabs } from './components/TicketsTabs'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { Model } from 'components/Form/Model'
import { TicketAccommodationFilter } from './components/filters/TicketAccommodationFilter'
import { TicketProgressFilter } from './components/filters/TicketProgressFilter'
import { TicketUserFilter } from './components/filters/TicketUserFilter'
import { TicketDropdownButton } from './components/TicketDropdownButton'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import dayjs from 'dayjs'
import { isJbpInstance } from 'contracts/general/helpers/instanceIds'

interface Props {
  navigate: (path: string) => void
}

@observer
export class DashboardTickets extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly tickets: Collection<ITodoSearchResult, any, ITodoSearchResultFilter>
  private readonly actions: Collection<ITodoSearchResult, any, ITodoSearchResultFilter>
  private readonly model: Model<ITodoSearchResultFilter>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model({
      createdBy: context.user.id,
      sort: 'status',
      status: 'open',
      page: '0,10',
      buildingId: null,
      compoundId: null,
      userId: null,
    })
    this.tickets = new Collection(
      `/api/${context.instance.id}/todoLists/${context.defaults.ticketTodoListId}/todos`,
      this.model.values,
    )
    this.actions = new Collection(
      `/api/${context.instance.id}/todoLists/${context.defaults.actionTodoListId}/todos`,
      this.model.values,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.tickets.init({ observeQuery: true }))
    this.disposers.push(this.actions.init({ observeQuery: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  sortByDate(a: ITodoSearchResult | null, b: ITodoSearchResult | null) {
    if (!a || !b) {
      return 0
    }
    return dayjs(b.createdAt).diff(a.createdAt)
  }

  render() {
    if (!this.tickets.resources || !this.tickets.metadata || !this.actions.resources) {
      return (
        <Card>
          <div className='p-4 relative h-full'>
            <div className='font-bold text-xl mb-2'>Meine Tickets</div>
            <Spinner />
          </div>
        </Card>
      )
    }
    const t = this.tickets.resources
      .concat(this.actions.resources)
      .sort((a, b) => this.sortByDate(a.data, b.data))
    return (
      <div className='bg-white rounded-md shadow-md col-span-1 relative'>
        <div className='absolute top-0 left-0 right-0 bottom-0 overflow-x-hidden overflow-y-auto'>
          <div className='sticky bg-white top-0 z-10 px-4 pt-4'>
            <div className='font-bold text-xl'>Meine Tickets</div>
            <TicketDropdownButton navigate={this.props.navigate} />
            <TicketsTabs tickets={this.tickets} />

            {isJbpInstance(this.context.instance.id) && (
              <div className='flex flex-wrap border-t border-b border-gray-200 bg-gray-100 py-2 px-4 -mx-4 mb-4'>
                <TicketAccommodationFilter model={this.model} />
                <TicketUserFilter model={this.model} />
                <TicketProgressFilter model={this.model} />
              </div>
            )}
          </div>

          {this.tickets.resources?.length === 0 &&
            this.actions.resources.length === 0 && (
              <div className='text-gray-500 pl-4'>Es wurden keine Tickets gefunden.</div>
            )}
          <div className='pb-4 px-4'>
            {t.map((ticket) => {
              if (!ticket.data) {
                return null
              }
              return (
                <Ticket
                  key={ticket.id}
                  ticket={ticket.data}
                  navigate={this.props.navigate}
                />
              )
            })}
            {this.tickets.metadata.page && this.tickets.metadata.hasMoreItems && (
              <div className='text-center text-sm'>
                {/* eslint-disable */}
                <LoadMoreButton
                  collection={this.tickets}
                  incrementBy={10}
                  pluralText='Sie sehen alle ${count} Einträge'
                />
                {/* eslint-enable */}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
