import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from 'components/Form/components/Button'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputDate } from 'components/Form/components/InputDate'
import { Message } from 'components/Message'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

const dateOptions: InputSelectOption[] = [
  { value: null, label: 'Bitte wählen...' },
  { value: 'dateOfBirth', label: 'Geburtsdatum' },
  { value: 'reponsibilityBeginDate', label: 'Zuständigkeitsbeginn' },
  { value: 'reponsibilityEndDate', label: 'Zuständigkeitsende' },
  { value: 'createdAt', label: 'Bewohner angelegt am' },
]

interface Props {
  model: Model<IResidentSearchResultsFilter>
}

@observer
export class SelectDate extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly options: InputSelectOption[]

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.options = [
      { value: null, label: 'Bitte wählen...' },
      { value: 'dateOfBirth', label: 'Geburtsdatum' },
      { value: 'reponsibilityBeginDate', label: 'Zuständigkeitsbeginn' },
      { value: 'reponsibilityEndDate', label: 'Zuständigkeitsende' },
    ]
    if (!context.permissions.host_lfgb) {
      this.options.push({ value: 'createdAt', label: 'Bewohner angelegt am' })
    }
    makeObservable(this)
  }

  @action private setToSet = () => {
    this.props.model.values.dateState = 'set'
  }
  @action private setToEmpty = () => {
    this.props.model.values.dateState = 'empty'
  }

  @action private reset = () => {
    this.props.model.values.dateType = null
    this.props.model.values.dateState = 'set'
    this.props.model.values.dateBegin = null
    this.props.model.values.dateEnd = null
  }

  render() {
    const responsibilityMissing: boolean =
      (this.props.model.values.dateType === 'reponsibilityBeginDate' ||
        this.props.model.values.dateType === 'reponsibilityEndDate') &&
      (!this.props.model.values.responsibleCompoundId ||
        this.props.model.values.responsibleCompoundId === '0')
    let label = 'Datum'
    let color: string = 'text-gray-500'
    if (
      this.props.model.values.dateType &&
      !responsibilityMissing &&
      (this.props.model.values.dateState === 'empty' ||
        this.props.model.values.dateBegin ||
        this.props.model.values.dateEnd)
    ) {
      label =
        (this.props.model.values.dateState === 'empty' ? 'Kein ' : '') +
          dateOptions.filter((o) => o.value === this.props.model.values.dateType)[0]
            ?.label ?? 'Gewählter Datumstyp'
      color = 'text-blue-500'
    }
    return (
      <Menu as='div' className='flex-content mr-6 cursor-pointer relative'>
        <Menu.Button className={color}>
          {label} <i className='fas fa-caret-down' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 rounded-md shadow-lg p-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40 w-60'>
            <div className='grid grid-cols-2 gap-4'>
              <InputSelect
                className='col-span-2'
                model={this.props.model}
                name='dateType'
                label='Typ'
                options={dateOptions}
                onChange={this.setToSet}
              />
              {this.props.model.values.dateType === 'dateOfBirth' && (
                <div className='flex col-span-2'>
                  <Button
                    onClick={this.setToSet}
                    color={
                      this.props.model.values.dateState === 'set'
                        ? 'primary'
                        : 'secondary'
                    }
                    outline={this.props.model.values.dateState !== 'set'}
                    className='flex-auto'
                    style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    Eingetragen
                  </Button>
                  <Button
                    onClick={this.setToEmpty}
                    color={
                      this.props.model.values.dateState !== 'set'
                        ? 'primary'
                        : 'secondary'
                    }
                    outline={this.props.model.values.dateState === 'set'}
                    className='flex-auto'
                    style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    Leer
                  </Button>
                </div>
              )}
              {this.props.model.values.dateState === 'set' && !responsibilityMissing && (
                <>
                  <InputDate
                    model={this.props.model}
                    name='dateBegin'
                    label='Zwischen Datum'
                  />
                  <InputDate model={this.props.model} name='dateEnd' label='und Datum' />
                </>
              )}
              {responsibilityMissing && (
                <Message color='danger' className='col-span-2'>
                  Diese Auswahl ist nur möglich, wenn Sie im Zuständigkeitsfilter ein
                  Gelände auswählen.
                </Message>
              )}
              <Menu.Item>
                <div className='col-span-2 flex'>
                  <Button
                    color='secondary'
                    outline
                    className='flex-auto'
                    onClick={this.reset}
                    style={{ borderRadius: '6px 0 0 6px' }}
                  >
                    Zurücksetzen
                  </Button>
                  <Button
                    color='primary'
                    className='flex-content'
                    style={{ borderRadius: '0 6px 6px 0' }}
                  >
                    OK
                  </Button>
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
