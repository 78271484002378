import * as React from 'react'
import { ResidentCardProps } from '../../interfaces/ResidentCardProps'
import styles from './styles.module.scss'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import { dayjs } from 'helpers/dayjs'
import { encodeBarcode } from 'modules/Pdf/helpers/encodeBarcode'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { getResidentSexDe } from 'contracts/residents/helpers/getResidentSexDe'
import { getEarliestBookingInCurrentResponsibility } from 'modules/Residents/helpers/getEarliestBookingInCurrentResponsibility'

export class ScheckkarteBarcode extends React.Component<ResidentCardProps, {}> {
  /*private getAccommodation = (): {
    roomLabel: string
    responsibilityBeginDateDe: string
  } => {
    const bookings = this.props.resident.data?.bookings ?? []
    bookings.sort((a, b) => (a.beginAt < b.beginAt ? -1 : 1))
    const current = bookings.filter(
      (b) => b.timeline === 'present' && b.type === 'internal-residence',
    )[0]
    if (!current) {
      return { roomLabel: '-', responsibilityBeginDateDe: '-' }
    }
    let responsitility: any = null
    for (const booking of bookings) {
      if (booking.beginAt > current.beginAt) {
        break
      }
      if (booking.type === 'responsibility-begin') {
        responsitility = booking
      }
      if (booking.type === 'responsibility-end') {
        responsitility = null
      }
    }
    return {
      roomLabel: current.label?.split(' / ').pop() || '',
      responsibilityBeginDateDe: responsitility
        ? dayjs(responsitility.beginAt).format('DD.MM.YYYY')
        : '-',
    }
  }*/

  render() {
    //const accommodation = this.getAccommodation()
    return (
      <>
        <div
          className={styles.page}
          style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
        >
          <span style={{ fontSize: 18, position: 'absolute', left: 133, top: 8 }}>
            Bewohnerausweis
          </span>
          <img
            src={getResidentImageSrc(
              this.props.instanceId,
              this.props.resident.imageId,
              this.props.resident.sex,
              'portrait',
            )}
            alt='Bewohnerbild'
            style={{ position: 'absolute', top: 11, left: 11, width: 112 }}
          />

          <div
            className='absolute flex flex-col overflow-hidden'
            style={{ left: 133, top: 40, right: 8, fontSize: 12, lineHeight: '12px' }}
          >
            {/* Vorname / JBP-ID */}
            <div className='flex'>
              <div className='flex-[0_1_auto]' style={{ marginRight: 20 }}>
                <div className='truncate' style={{ maxWidth: 140, minWidth: 62 }}>
                  {this.props.resident.firstName}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Vorname</div>
              </div>
              <div className='flex-content mr-auto'>
                <div className='truncate'>{toJbpId(+this.props.resident.id)}&nbsp;</div>
                <div style={{ fontSize: 9 }}>JBP-ID</div>
              </div>
            </div>

            {/* Nachname / T-ID */}
            <div className='flex' style={{ marginTop: 8 }}>
              <div className='flex-[0_1_auto]' style={{ marginRight: 20 }}>
                <div className='truncate' style={{ maxWidth: 140, minWidth: 62 }}>
                  {this.props.resident.lastName}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Nachname</div>
              </div>
              <div className='flex-content mr-auto'>
                <div className='truncate'>{this.props.resident.data.tId}&nbsp;</div>
                <div style={{ fontSize: 9 }}>T-ID</div>
              </div>
            </div>

            {/* Date of birth / nationality */}
            <div className='flex' style={{ marginTop: 8 }}>
              <div className='flex-content' style={{ marginRight: 20 }}>
                <div>
                  {this.props.resident.dateOfBirth
                    ? dayjs(this.props.resident.dateOfBirth).format('DD.MM.YYYY')
                    : '-'}
                  &nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Geburtsdatum</div>
              </div>
              <div className='flex-auto'>
                <div className='truncate'>
                  {this.props.resident.data.countryOfBirth || '-'}
                  &nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Herkunftsland</div>
              </div>
            </div>
          </div>

          <div
            className='text-center absolute'
            style={{
              fontFamily: 'Barcode',
              fontSize: 40,
              marginTop: -11,
              paddingBottom: 0,
              transform: 'scaleY(-2.3)',
              width: 340,
              bottom: 30,
            }}
          >
            {encodeBarcode(this.props.resident.token || 'EXAMPLE')}
          </div>
        </div>
        {!this.props.batch && (
          <div
            className={styles.page}
            style={this.props.batch ? {} : { pageBreakAfter: 'always' }}
          >
            <div className='mx-auto mt-2 w-fit' style={{ fontSize: 18 }}>
              Bewohnerausweis
            </div>
            <div className='grid grid-cols-2 gap-4 px-4 pt-6' style={{ fontSize: 12 }}>
              <div style={{ marginRight: 20 }}>
                <div className='truncate' style={{ maxWidth: 140, minWidth: 62 }}>
                  {this.props.resident.data.accommodation?.building?.label}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Gebäude</div>
              </div>
              <div className='mr-auto'>
                <div className='truncate'>
                  {this.props.resident.data.accommodation?.room?.label}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Zimmer</div>
              </div>
            </div>
            <div className='flex gap-3 px-4 py-2' style={{ fontSize: 12 }}>
              <div>
                <div className='truncate'>
                  {this.props.resident.data.accommodation?.building?.street}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Straße</div>
              </div>
              <div className='mr-auto'>
                <div className='truncate'>
                  {this.props.resident.data.accommodation?.building?.zip}{' '}
                  {this.props.resident.data.accommodation?.building?.city}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>PLZ, Stadt</div>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4 px-4' style={{ fontSize: 12 }}>
              <div style={{ marginRight: 20 }}>
                <div className='truncate' style={{ maxWidth: 140, minWidth: 62 }}>
                  {this.props.resident.data.bvNr}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>BV-Nummer</div>
              </div>
              <div className='mr-auto'>
                <div className='truncate'>
                  {getResidentSexDe(this.props.resident.sex)}&nbsp;
                </div>
                <div style={{ fontSize: 9 }}>Geschlecht</div>
              </div>
            </div>
            <div className='mt-2 px-4' style={{ fontSize: 12 }}>
              <div className='truncate'>
                {this.props.resident.data.bookings
                  ? dayjs(
                      getEarliestBookingInCurrentResponsibility(
                        this.props.resident.data.bookings,
                      )?.beginAt,
                    ).format('DD.MM.YYYY')
                  : ''}
                &nbsp;
              </div>
              <div style={{ fontSize: 9 }}>Eintrittsdatum</div>
            </div>
          </div>
        )}
      </>
    )
  }
}
