import { InputAsyncMultiSelect } from 'components/Form/components/InputAsyncMultiSelect'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import dayjs from 'dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { TicketPriority } from './TicketPriority'
import { DoneBy } from './DoneBy'
import { getTicketStatusDe } from '../helpers/getTicketStatusDe'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { TicketRating } from './TicketRating'
import { InputUser } from 'components/Form/components/InputUser'
import { ITodoMessage } from 'contracts/todos/interfaces/ITodoMessage'
import { Resource, hermes } from '@byll/hermes'
import { InputCompound } from 'components/Form/components/InputCompound'
import { TicketWatcher } from './TicketWatcher'

interface Props {
  model: Model<ITodoSearchResult>
  summary: Resource<ITodoMessage> | undefined
}

@observer
export class TicketDialogTicket extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private toggleTodo = async (e: any) => {
    if (!e.target.checked) {
      await hermes.delete(
        `/api/${this.context.instance.id}/todos/${this.props.model.values.id}/todoMessages/${this.props.summary?.data?.id}`,
      )
    }
    const date = this.props.model.values.doneDate ? null : dayjs().format('YYYY-MM-DD')
    runInAction(() => {
      this.props.model.values.doneDate = date
      this.props.model.values.doneBy = null
      this.props.model.values.doneAt = null
      this.props.model.values.rating = null
    })
  }

  render() {
    const { model } = this.props
    const isTicket = model.values.listId === this.context.defaults.ticketTodoListId
    const status = getTicketStatusDe(model.values)
    const isDisabled =
      !!model.values.id &&
      (this.context.permissions.dashboard_tickets < 3 || !!model.values.doneDate)
    const isAssigner =
      !model.values.doneDate && this.context.permissions.dashboard_tickets > 1
    const canCheck =
      model.values.assignees.map((a) => a.id).includes(this.context.user.id) &&
      !!this.props.summary &&
      !model.values.rating
    return (
      <div className='rounded-sm shadow bg-white flex relative mb-6'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7'>
          <div>{isTicket ? 'Ticketbeschreibung' : 'Maßnahmenbeschreibung'}</div>
          <div className='text-gray-400 text-xs'>
            Verwenden Sie {isTicket ? 'Tickets' : 'Maßnahmen'}
            <br />
            {isTicket ? 'für Ihre Supportanfragen.' : 'für Ihre Aufgaben.'}
          </div>
        </div>

        <div className='flex-auto pt-11 py-8 pr-6'>
          {this.error && <Message color='danger'>{this.error}</Message>}
          <div id={model.id} className='flex flex-col gap-4'>
            <div className='flex items-center'>
              {!!model.values.id && model.values.rating && (
                <TicketRating key={model.values.rating} rating={model.values.rating} />
              )}
              {!!model.values.id && (
                <span className='rounded-full text-xs bg-indigo-500 text-white py-1 px-3'>
                  {status}
                </span>
              )}
              {!!model.values.id && (
                <TicketPriority
                  model={model}
                  name='priority'
                  disabled={!isAssigner && isDisabled}
                />
              )}
              {!!model.values.id && this.props.model.values.watcherIds.length > 0 && (
                <div className='ml-auto'>
                  <TicketWatcher model={this.props.model} />
                </div>
              )}
            </div>
            <div className='flex'>
              {!!model.values.id && (
                <div className='flex-content pt-[6px]'>
                  <input
                    disabled={!canCheck}
                    type='checkbox'
                    checked={!!model.values.doneDate}
                    className={`${
                      canCheck ? 'cursor-pointer' : 'bg-gray-200'
                    } rounded-full ${
                      !!model.values.doneDate ? 'border-0' : 'border border-gray-300'
                    } h-5 w-5 text-green-500 mr-2`}
                    onChange={this.toggleTodo}
                  />
                </div>
              )}
              <InputText
                className='flex-auto'
                model={model}
                name='label'
                label='Betreff'
                disabled={isDisabled}
              />
              {!!model.values.id && (
                <InputDate
                  className='flex-[0_0_100px] ml-4'
                  model={model}
                  name='dueDate'
                  label='Fällig am'
                  disabled={!isAssigner && isDisabled}
                  placeholder='Heute'
                />
              )}
            </div>
            <InputTextarea
              model={model}
              name='notes'
              label='Aufgabenbeschreibung'
              rows={3}
              disabled={isDisabled}
            />
            <div className='flex gap-4'>
              <InputUser
                className='w-1/3'
                model={this.props.model}
                name='ratingBy'
                label='Zu bewerten von'
                disabled={isDisabled}
              />
              <InputCompound
                className='w-1/3'
                label='Gelände'
                name='compoundId'
                model={this.props.model}
                disabled={isDisabled}
              />
              <InputBuilding
                className='w-1/3'
                model={this.props.model}
                name='buildingId'
                label='Gebäude'
                compoundId={this.props.model.values.compoundId}
                disabled={isDisabled || !this.props.model.values.compoundId}
              />
            </div>
            {(!isTicket ||
              this.context.permissions.dashboard_tickets === 2 ||
              !!model.values.id) && (
              <InputAsyncMultiSelect
                className='flex-auto'
                disabled={
                  (!!model.values.id && !!model.values.doneDate) ||
                  (!!model.values.id &&
                    this.context.permissions.dashboard_tickets < 2 &&
                    isTicket)
                }
                model={this.props.model}
                name='assigneeIds'
                label='Zu erledigen von'
                options={`/api/${this.context.instance.id}/usersAndRoles`}
              />
            )}
            {model.values.doneDate && model.values.doneBy && model.values.doneAt && (
              <DoneBy userId={model.values.doneBy} doneAt={model.values.doneAt} />
            )}
          </div>
        </div>
      </div>
    )
  }
}
