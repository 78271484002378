import * as React from 'react'
import { Collection } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { InputCompound } from 'components/Form/components/InputCompound'
import { Model } from 'components/Form/Model'
import { SideBarLayout } from 'components/SideBarLayout'
import { Spinner } from 'components/Spinner'
import { IInventoryFolder } from 'contracts/inventory/interfaces/IInventoryFolder'
import { IInventoryItem } from 'contracts/inventory/interfaces/IInventoryItem'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Routes, Route, useParams, useNavigate, Navigate } from 'react-router'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { SideBar } from 'components/SideBarLayout/components/SideBar'
import { Tree } from 'components/Tree'
import { IInventoryBundle } from 'contracts/inventory/interfaces/IInventoryBundle'
import { ITreeNode } from 'contracts/general/interfaces/ITreeNode'
import { box } from 'services/box'
import { BundleFormDialog } from './components/InventoryBundle/components/BundleFormDialog'
import { Button } from 'components/Form/components/Button'
import { OrdersLog } from './components/InventoryOrder/components/OrdersLog'
import { InventoryOrderPreview } from './components/InventoryOrder/components/InventoryOrderPreview'
import { isStammCompound } from 'helpers/isStamm'
import { InventoryOrderNew } from './components/InventoryOrder/components/InventoryOrderNew'
import { IOrderSearchResult } from 'contracts/inventory/interfaces/IOrderSearchResult'
import { Callout } from 'components/Callout'
import { InventoryItem } from './components/InventoryItem'
import { InventoryItemNew } from './components/InventoryItem/components/InventoryItemNew'
import { InventoryItemPlaceholder } from './components/InventoryFolder/components/InventoryFolderPlaceholder'
import { InventoryBundlePlaceholder } from './components/InventoryBundle/components/InventoryBundlePlaceholder'
import { InventoryFolder } from './components/InventoryFolder'
import { InventoryFolderNew } from './components/InventoryFolder/components/InventoryFolderNew'
import { InventoryBundleNew } from './components/InventoryBundle/components/InventoryBundleNew'
import { InventoryBundle } from './components/InventoryBundle'
import { InventoryOrderPlaceholder } from './components/InventoryOrder/components/InventoryOrderPlaceholder'
import { InputInventoryOrder } from 'components/Form/components/InputInventoryOrder'
import { InventoryCorrectionPlaceholder } from './components/InventoryCorrection/components/InventoryCorrectionPlaceholder'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'

interface Props {
  navigate: (url: string) => void
  pathname: string
}

@observer
export class Inventory extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ compoundId: string | null }>
  private readonly folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
  private readonly bundles: Collection<IInventoryBundle>
  private readonly orders: Collection<IOrderSearchResult, { type: 'order' | 'inventory' }>
  private readonly corrections: Collection<
    IOrderSearchResult,
    { type: 'order' | 'inventory' }
  >
  private readonly disposers: Disposer[] = []
  @observable private inventoryQuery: { compoundId: string | null; type: 'inventory' }
  @observable private orderQuery: { compoundId: string | null; type: 'order' }
  @observable private paths: { label: string; id?: string }[] = []
  @observable private tab: 'inventory' | 'bundle' | 'correction' | 'order' = 'inventory'

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.model = new Model({ compoundId: context.defaults.responsibilityCompoundId })
    if (
      context.permissions.menu_inventoryManagement === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null
    }
    if (props.pathname.startsWith('/inventory/bundles')) {
      this.tab = 'bundle'
    } else if (props.pathname.startsWith('/inventory/correction')) {
      this.tab = 'correction'
    } else if (props.pathname.startsWith('/inventory/orders')) {
      this.tab = 'order'
    } else {
      this.tab = 'inventory'
    }

    this.inventoryQuery = { compoundId: this.model.values.compoundId, type: 'inventory' }
    this.orderQuery = { compoundId: this.model.values.compoundId, type: 'order' }

    this.folders = new Collection(
      `/api/${context.instance.id}/inventory/folders`,
      this.model.values,
    )
    this.bundles = new Collection(
      `/api/${context.instance.id}/inventory/bundles`,
      this.model.values,
    )
    this.corrections = new Collection(
      `/api/${context.instance.id}/inventory/orderSearchResults`,
      this.inventoryQuery,
    )
    this.orders = new Collection(
      `/api/${context.instance.id}/inventory/orderSearchResults`,
      this.orderQuery,
    )
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.model.values.compoundId,
        (compoundId) => {
          this.inventoryQuery.compoundId = compoundId
          this.orderQuery.compoundId = compoundId
        },
        { fireImmediately: true },
      ),
    )
    this.disposers.push(this.bundles.init({ observeQuery: true }))
    this.disposers.push(this.folders.init({ observeQuery: true }))
    this.disposers.push(this.corrections.init({ observeQuery: true }))
    this.disposers.push(this.orders.init({ observeQuery: true }))
    reaction(
      () => this.props.pathname,
      () => this.getPath(),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  getPath = () => {
    const id = this.props.pathname.split('/')[3]
    if (!id) {
      return
    }
    this.paths = []
    if (id.includes('f')) {
      const folder = this.folders.resources?.find((f) => f.data?.id === id.split('.')[1])
      if (!folder?.data) {
        return
      }
      this.paths.push({ label: folder?.data?.label })
    } else if (id.includes('i')) {
      const folder = this.folders.resources?.filter(
        (f) => f.data?.items.some((i) => i.id === id.split('.')[1]),
      )[0]
      const item = folder?.data?.items.find((i) => i.id === id.split('.')[1])
      if (!folder?.data || !item) {
        return
      }
      this.paths.push(
        { label: folder.data.label, id: 'f.' + folder.id },
        { label: item.label },
      )
    }
  }

  prepareBundleNodes = () => {
    const bundleNodes: ITreeNode[] = []
    if (!this.bundles.resources) {
      return []
    }
    for (const bundle of this.bundles?.resources) {
      if (!bundle.data) {
        return []
      }
      bundleNodes.push({
        key: `/inventory/bundles/${bundle.id}`,
        title: bundle.data?.label,
        children: this.getBundleItems(bundle.data?.id),
        icon: 'fas fa-box-open',
      })
    }
    if (this.context.permissions.menu_inventoryManagement > 0) {
      bundleNodes.unshift({
        key: '/inventory/bundles/new',
        title: 'Neues Paket',
        children: [],
        icon: 'fas fa-plus',
      })
    }
    return bundleNodes
  }

  prepareFolderNodes = () => {
    if (!this.folders.resources) {
      return []
    }
    const nodes: ITreeNode[] = this.folders.resources.map((f) => {
      if (!f.data) {
        return { key: '', title: '', children: [] }
      }
      return {
        key: `/inventory/folders/${f.id}`,
        title: f.data.label,
        children: f.data.items.map((i) => {
          return {
            key: `/inventory/items/${i.id}`,
            title: i.label,
            children: [],
            icon: 'fas fa-file',
            color: 'text-fuchsia-400',
          }
        }),
      }
    })
    if (this.context.permissions.inventory_manageFolders > 0) {
      nodes.unshift({
        key: '/inventory/folders/new',
        title: 'Neuer Ordner',
        children: [],
        icon: 'fas fa-plus',
      })
    }
    return nodes
  }

  getBundleItems = (bundleId: string) => {
    const bundleItems: ITreeNode[] = []
    const folderItems: any = []
    if (!this.folders.resources) {
      return []
    }
    for (const folder of this.folders.resources) {
      folderItems.push(folder.data?.items.filter((i) => i.bundleIds.includes(bundleId)))
    }
    for (const items of folderItems) {
      if (items && items.length > 0) {
        const bundleItem = items.map((i) => {
          return {
            key: `/inventory/items/${i.id}`,
            title: i.label,
            children: [],
            icon: 'fas fa-file',
            color: 'text-fuchsia-400',
          }
        })
        bundleItems.push.apply(bundleItems, bundleItem)
      }
    }
    return bundleItems
  }

  openBundleDialog = async () => {
    if (!this.model.values.compoundId) {
      return
    }
    const promise = box.custom(
      <BundleFormDialog
        compoundId={this.model.values.compoundId}
        onClose={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  @action
  private setTabInventory = () => {
    this.tab = 'inventory'
    this.props.navigate('/inventory/items')
  }

  @action
  private setTabBundles = () => {
    this.tab = 'bundle'
    this.props.navigate('/inventory/bundles')
  }

  @action
  private setTabCorrection = () => {
    this.tab = 'correction'
    this.props.navigate('/inventory/corrections')
  }

  @action
  private setTabOrders = () => {
    this.tab = 'order'
    this.props.navigate('/inventory/orders')
  }

  private goToOrder = (orderId: string) => {
    this.props.navigate(`/inventory/orders/${orderId}`)
  }

  private compoundFilter = (compound: ICompound) => compound.hasInventory

  render() {
    if (
      !this.folders.resources ||
      !this.bundles.resources ||
      (!this.orders.resources && this.context.inventory_manageOrders > 0)
    ) {
      return <Spinner />
    }
    const folderNodes = this.prepareFolderNodes()
    const bundleNodes = this.prepareBundleNodes()
    return (
      <SideBarLayout>
        {/* Sidebar */}
        <SideBar>
          <div
            style={{ flex: '0 0 64px', lineHeight: '64px' }}
            className='pl-6 pr-4 flex text-lg bg-white'
          >
            <div className='flex-content'>
              <i className='far fa-folder-open text-gray-400' />
              <span className='ml-3 text-gray-500'>Inventar</span>
            </div>
            {this.model.values.compoundId && (
              <div
                className='flex-auto ml-3 mt-[13px] h-[38px]'
                style={{ lineHeight: 'initial' }}
              >
                <InputInventoryOrder
                  compoundId={this.model.values.compoundId}
                  onChoose={this.goToOrder}
                  placeholder='Bedarfsnummer'
                />
              </div>
            )}
          </div>
          <div className='bg-gray-100 border-gray-200 border-t border-b p-4'>
            <InputCompound
              autoSelectIfOnlyOneOption
              saveResponsibility
              onlyStamm={this.context.permissions.menu_inventoryManagement === 1}
              model={this.model}
              name='compoundId'
              onChange={
                this.tab === 'order'
                  ? () => this.props.navigate('/inventory/order')
                  : () => this.props.navigate('/inventory')
              }
              filter={this.compoundFilter}
            />
          </div>
          <div className='flex-auto relative'>
            <div className='absolute top-0 bottom-0 right-0 left-0 overflow-x-hidden overflow-y-auto'>
              <div className='p-4 flex'>
                <Button
                  className='border-r border-transparent flex-auto'
                  style={{
                    borderRadius: '6px 0 0 6px',
                    marginRight: '-1px',
                    padding: '8px 12px',
                  }}
                  color='primary'
                  outline={this.tab === 'inventory' ? false : true}
                  onClick={this.setTabInventory}
                >
                  Ordner
                </Button>
                <Button
                  className='flex-auto'
                  color='primary'
                  style={{
                    borderRadius: '0 0 0 0',
                    padding: '8px 12px',
                    marginRight: '-1px',
                  }}
                  outline={this.tab === 'bundle' ? false : true}
                  onClick={this.setTabBundles}
                >
                  Pakete
                </Button>
                <Button
                  className='flex-auto'
                  color='primary'
                  style={{ borderRadius: '0 0 0 0', padding: '8px 12px' }}
                  outline={this.tab === 'correction' ? false : true}
                  onClick={this.setTabCorrection}
                >
                  Inventur
                </Button>
                <Button
                  className='flex-auto'
                  color='primary'
                  style={{
                    borderRadius: '0 6px 6px 0',
                    marginLeft: '-1px',
                    padding: '8px 12px',
                  }}
                  outline={this.tab === 'order' ? false : true}
                  onClick={this.setTabOrders}
                >
                  Shop
                </Button>
              </div>
              {this.context.permissions.inventory_manageOrders === 0 &&
                this.tab === 'order' &&
                this.model.values.compoundId && (
                  <div className='text-gray-600 pt-2 px-5 text-sm'>
                    Sie haben leider nicht die nötige Berechtigungsstufe, um Bestellungen
                    in dieser Unterkunft einzusehen.
                  </div>
                )}
              {this.context.permissions.inventory_manageOrders === 0 &&
                this.tab === 'correction' &&
                this.model.values.compoundId && (
                  <div className='text-gray-600 pt-2 px-5 text-sm'>
                    Sie haben leider nicht die nötige Berechtigungsstufe, um Inventuren in
                    dieser Unterkunft einzusehen.
                  </div>
                )}
              {this.context.permissions.inventory_manageOrders > 0 &&
                this.tab === 'order' &&
                this.orders.resources &&
                this.model.values.compoundId && (
                  <>
                    {this.orders.resources.length === 0 && (
                      <div className='text-gray-600 pt-2 pl-5 text-sm'>
                        Es sind keine Bestellungen vorhanden
                      </div>
                    )}
                    <OrdersLog
                      orders={this.orders}
                      compoundId={this.model.values.compoundId}
                      navigate={this.props.navigate}
                      selectedId={this.props.pathname.substring(18) || null}
                    />
                  </>
                )}
              {this.context.permissions.inventory_manageOrders > 0 &&
                this.tab === 'correction' &&
                this.corrections.resources &&
                this.model.values.compoundId && (
                  <>
                    {this.corrections.resources.length === 0 && (
                      <div className='text-gray-600 pt-2 pl-5 text-sm'>
                        Es sind keine Inventuren vorhanden
                      </div>
                    )}
                    <OrdersLog
                      orders={this.corrections}
                      compoundId={this.model.values.compoundId}
                      navigate={this.props.navigate}
                      selectedId={this.props.pathname.substring(23) || null}
                    />
                  </>
                )}
              {this.tab === 'inventory' && (
                <>
                  {folderNodes.length === 0 && (
                    <div className='text-gray-600 pt-2 pl-5 text-sm'>
                      Es sind keine Ordner vorhanden
                    </div>
                  )}
                  {folderNodes && (
                    <Tree
                      className='flex-auto'
                      nodes={folderNodes}
                      onSelect={(key) => this.props.navigate(key)}
                      selected={this.props.pathname}
                    />
                  )}
                </>
              )}
              {this.tab === 'bundle' && (
                <>
                  {bundleNodes.length === 0 && (
                    <div className='text-gray-600 pt-2 pl-5 text-sm'>
                      Es sind keine Pakete vorhanden
                    </div>
                  )}
                  {bundleNodes && (
                    <Tree
                      className='flex-auto'
                      nodes={bundleNodes}
                      onSelect={(key) => this.props.navigate(key)}
                      selected={this.props.pathname}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </SideBar>

        {/* Content */}
        <div className='md:ml-80 flex flex-col flex-1 min-h-screen relative'>
          {this.model.values.compoundId && (
            <Routes>
              <Route path='/items' element={<InventoryItemPlaceholder />} />
              <Route
                path='/items/:id'
                element={<RenderInventoryItem folders={this.folders} />}
              />
              <Route path='/folders' element={<InventoryItemPlaceholder />} />
              <Route
                path='/folders/new'
                element={
                  <RenderInventoryFolderNew compoundId={this.model.values.compoundId} />
                }
              />
              <Route
                path='/folders/:id'
                element={<RenderInventoryFolder folders={this.folders} />}
              />
              <Route
                path='/folders/:id/new'
                element={<RenderInventoryItemNew folders={this.folders} />}
              />
              <Route path='/bundles' element={<InventoryBundlePlaceholder />} />
              <Route
                path='/bundles/new'
                element={
                  <RenderInventoryBundleNew compoundId={this.model.values.compoundId} />
                }
              />
              <Route
                path='/bundles/:id'
                element={
                  <RenderInventoryBundleOverview
                    compoundId={this.model.values.compoundId}
                    bundles={this.bundles}
                  />
                }
              />
              <Route path='/corrections' element={<InventoryCorrectionPlaceholder />} />
              <Route
                path='/corrections/new'
                element={
                  <RenderNewCorrection
                    compoundId={this.model.values.compoundId}
                    folders={this.folders}
                  />
                }
              />
              <Route path='/corrections/:id/*' element={<RenderOrderOverview />} />
              <Route path='/orders' element={<InventoryOrderPlaceholder />} />
              <Route
                path='/orders/new'
                element={
                  <RenderNewOrder
                    compoundId={this.model.values.compoundId}
                    folders={this.folders}
                  />
                }
              />
              <Route path='/orders/:id/*' element={<RenderOrderOverview />} />
              <Route path='*' element={<Navigate to='/inventory/items' replace />} />
            </Routes>
          )}
          {!this.model.values.compoundId && (
            <div className='rounded-md shadow-md mx-4 bg-white mt-[72px] flex-auto mb-4 flex flex-col'>
              <div className='my-auto flex-content'>
                <Callout
                  icon='fas fa-building'
                  iconColor='#4f47e5'
                  title='Bitte wählen Sie ein Gelände aus'
                />
              </div>
            </div>
          )}
        </div>
      </SideBarLayout>
    )
  }
}

export const RenderInventoryItem: React.FC<{
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
}> = ({ folders }) => {
  const params = useParams<{ id: string }>()

  return <InventoryItem key={params.id || ''} id={params.id || ''} folders={folders} />
}

export const RenderInventoryFolder: React.FC<{
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
}> = ({ folders }) => {
  const params = useParams<{ id: string }>()
  const folder = folders.resources?.find((f) => f.id === params.id)
  if (!folder?.data) return <InventoryItemPlaceholder />

  return <InventoryFolder key={folder.id} folder={folder.data} />
}

export const RenderInventoryItemNew: React.FC<{
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
}> = ({ folders }) => {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const folder = folders.resources?.find((f) => f.id === params.id)
  if (!folder?.data) return <InventoryItemPlaceholder />

  return <InventoryItemNew key={folder.id} folder={folder.data} navigate={navigate} />
}

export const RenderInventoryFolderNew: React.FC<{ compoundId: string }> = ({
  compoundId,
}) => {
  const navigate = useNavigate()
  return <InventoryFolderNew navigate={navigate} compoundId={compoundId} />
}

export const RenderInventoryBundleNew: React.FC<{ compoundId: string }> = ({
  compoundId,
}) => {
  const navigate = useNavigate()
  return <InventoryBundleNew navigate={navigate} compoundId={compoundId} />
}

export const RenderOrderOverview: React.FC<{}> = () => {
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  if (!params.id) {
    return null
  }

  return <InventoryOrderPreview key={params.id} orderId={params.id} navigate={navigate} />
}

export const RenderNewOrder: React.FC<{
  compoundId: string
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
}> = ({ compoundId, folders }) => {
  const navigate = useNavigate()
  return (
    <InventoryOrderNew
      compoundId={compoundId}
      navigate={navigate}
      type='order'
      folders={folders}
    />
  )
}

export const RenderNewCorrection: React.FC<{
  compoundId: string
  folders: Collection<IInventoryFolder & { items: IInventoryItem[] }>
}> = ({ compoundId, folders }) => {
  const navigate = useNavigate()
  return (
    <InventoryOrderNew
      compoundId={compoundId}
      navigate={navigate}
      type='inventory'
      folders={folders}
    />
  )
}

export const RenderInventoryBundleOverview: React.FC<{
  compoundId: string
  bundles: Collection<IInventoryBundle>
}> = (props) => {
  const params = useParams<{ id: string }>()
  if (!params.id || !props.bundles.resources) {
    return null
  }
  const bundle = props.bundles.resources.find((b) => b.id === params.id)?.data
  if (!bundle) {
    return null
  }

  return <InventoryBundle key={params.id} bundle={bundle} compoundId={props.compoundId} />
}
