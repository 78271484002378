import * as React from 'react'
import { hermes, Resource } from '@byll/hermes'
import { ICostPeriod } from 'contracts/costCoverages/interfaces/ICostPeriod'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { box } from 'services/box'
import { CompoundCheckOutDialog } from 'modules/Compounds/components/CompoundCheckOutDialog'
import { Model } from 'components/Form/Model'
import { action } from 'mobx'

interface Props {
  id: string
  model: Model<{ clear: number }>
}

const statusDict = {
  'in preparation': 'Abr. in Vorbereitung',
  'ready for billing': 'Abrechnungsbereit',
  billed: 'Abr. abgeschlossen',
}

const statusColors = {
  'in preparation': 'bg-yellow-500',
  'ready for billing': 'bg-green-500',
  billed: 'bg-gray-500',
}

@observer
export class CostPeriodStatus extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly resource: Resource<ICostPeriod>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.resource = new Resource(
      `/api/${context.instance.id}/costCoverages/costPeriods/${props.id}`,
    )
  }

  componentDidMount() {
    this.resource.init({ readOnly: true })
  }

  componentWillUnmount() {
    this.resource.dispose()
  }

  private setStatus = async (status: string) => {
    await hermes.update(
      `/api/${this.context.instance.id}/costCoverages/costPeriods/${this.props.id}`,
      { status },
    )
  }

  private openDialog = () => {
    if (!this.context.permissions.resident_plannedCheckOutDate) {
      return
    }
    const promise = box.custom(
      <CompoundCheckOutDialog
        compoundId={this.props.id.split('-')[0]}
        onClose={action(() => {
          promise.close()
          this.props.model.values.clear++
        })}
      />,
      { context: this.context },
    )
  }

  render() {
    const status = this.resource.data?.status ?? ''
    return (
      <Menu as='div' className='relative inline-block text-left w-full'>
        <Menu.Button
          className={`${
            statusColors[status] || 'bg-indigo-500'
          } text-white rounded-md text-center text-sm w-full`}
          style={{ padding: '5px 10px', height: 38 }}
        >
          {statusDict[status] || 'Abrechnung'} <i className='fas fa-caret-down' />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='z-10 origin-top-right divide-y divide-gray-200 absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white text-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              <Menu.Item>
                <span
                  onClick={() => this.setStatus('')}
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    status === '' ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                  }`}
                >
                  -
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  onClick={() => this.setStatus('in preparation')}
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    status === 'in preparation'
                      ? 'bg-blue-500 text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  In Vorbereitung
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  onClick={() => this.setStatus('ready for billing')}
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    status === 'ready for billing'
                      ? 'bg-blue-500 text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  Abrechnungsbereit
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  onClick={() => this.setStatus('billed')}
                  className={`cursor-pointer block px-4 py-2 text-sm ${
                    status === 'billed' ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                  }`}
                >
                  Abr. abgeschlossen
                </span>
              </Menu.Item>
            </div>
            {this.context.permissions.resident_plannedCheckOutDate && (
              <div className='py-1'>
                <Menu.Item>
                  <span
                    onClick={this.openDialog}
                    className='cursor-pointer block px-4 py-2 text-sm hover:bg-gray-100'
                  >
                    Unterkunft abgeben
                  </span>
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
