import * as React from 'react'
import styles from '../styles.module.scss'
import { IRealTimesWithDetails } from 'contracts/workplan/interfaces/IRealTimesWithDetails'
import { dayjs } from 'helpers/dayjs'
import { DAYS } from 'contracts/general/helpers/days'

interface Props {
  date: string
  holiday: string | null
  real: IRealTimesWithDetails
}

export const StundenzettelRow: React.FC<Props> = (props) => {
  const date = dayjs(props.date)
  return (
    <div
      className={styles.row}
      style={props.holiday ? { background: '#ff9800' } : undefined}
    >
      <div className={styles.colDate}>
        {props.date ? `${date.format('DD.MM.YYYY')} ${DAYS[date.day()]}.` : ''}
      </div>
      <div className={styles.colLabel}>
        {props.real.shiftId > 0 && props.real.label !== 'Unentschuldigtes Fehlen' ? (
          <>
            {dayjs(props.real.begin).format('HH:mm') +
              ' - ' +
              dayjs(props.real.end).format('HH:mm')}
            &nbsp;&nbsp;&nbsp;&nbsp;{props.real.label}
          </>
        ) : (
          props.real.label
        )}
      </div>
      <div className={styles.colBreak}>
        {props.real.shiftId > 0
          ? props.real.hoursBreak
            ? `${props.real.hoursBreak.toFixed(2).replace('.', ',')} h`
            : '-'
          : ''}
      </div>
      <div className={styles.colHours}>
        {props.real.shiftId > 0
          ? `${(props.real.workHoursTotal + props.real.onCallHoursTotal)
              .toFixed(2)
              .replace('.', ',')} h`
          : ''}
      </div>
    </div>
  )
}
