import React from 'react'
import { hermes } from '@byll/hermes'
import { Message } from 'components/Message'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { Spinner } from 'components/Spinner'
import { Button } from 'components/Form/components/Button'
import { dayjs } from 'helpers/dayjs'
import { Dayjs } from 'dayjs'

interface Props {
  compoundId: string
  checkOutDate: string
  onStep: (step: string) => void
  onClose: () => void
}

@observer
export class CompoundCheckOutDialogCheckOut extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly at: Dayjs
  @observable private residentIds: string[] | null = null
  @observable private warning: JSX.Element | null = null
  @observable private success: string = ''
  @observable private loading: boolean = false

  constructor(props: Props) {
    super(props)
    this.at = dayjs(props.checkOutDate).hour(0)
    makeObservable(this)
  }

  componentDidMount() {
    this.getOccupancies()
  }

  private getOccupancies = async () => {
    const data = await hermes.indexOnceNew<IOccupancy>(
      `/api/${
        this.context.instance.id
      }/accommodations/occupancies?queryAt=${encodeURIComponent(
        this.at.toISOString(),
      )}&queryTill=${encodeURIComponent(dayjs('2100-01-01').toISOString())}&compoundId=${
        this.props.compoundId
      }`,
    )
    const residentIds: Set<string> = new Set()
    for (const occupancy of data) {
      for (const bed of occupancy.beds) {
        if (bed.resident) {
          residentIds.add(bed.resident.id)
        }
      }
    }

    runInAction(() => {
      this.residentIds = Array.from(residentIds.values())
    })
  }

  private checkOut = async () => {
    runInAction(() => (this.loading = true))
    const { failedResidents } = await hermes.create(
      `/api/${this.context.instance.id}/accommodations/batchCheckOut`,
      {
        compoundId: this.props.compoundId,
        queryAt: this.at.toISOString(),
      },
    )
    runInAction(() => (this.loading = false))
    if (failedResidents.length === 0) {
      runInAction(() => (this.success = `Alle Personen wurden ausgecheckt.`))
    }
    if (failedResidents.length > 0) {
      runInAction(() => {
        if (failedResidents.length > 10) {
          this.warning = (
            <>
              {failedResidents.length} von {this.residentIds!.length} Personen konnten
              nicht ausgecheckt werden.`
            </>
          )
        } else {
          this.warning = (
            <>
              Folgende Personen konnten nicht ausgecheckt werden:
              <ul>
                {failedResidents.map((r) => (
                  <li key={r.id}>
                    {r.lastName}, {r.firstName}
                  </li>
                ))}
              </ul>
            </>
          )
        }
      })
    }
  }

  render() {
    if (!this.residentIds) {
      return <Spinner />
    }
    const isReadyForCheckOut = dayjs().isSameOrAfter(
      dayjs(this.props.checkOutDate).subtract(1, 'day'),
      'day',
    )
    return (
      <>
        <div className='mt-2'>
          <Message color='primary'>
            {this.residentIds.length > 0
              ? `Es ${
                  this.residentIds.length === 1 ? 'befindet' : 'befinden'
                } sich aktuell ${this.residentIds.length} ${
                  this.residentIds.length === 1 ? 'Person' : 'Personen'
                } in diesem Gelände. ${
                  isReadyForCheckOut
                    ? 'Sie können diese jetzt auschecken.'
                    : 'Sie können diese ab dem ' +
                      dayjs(this.props.checkOutDate)
                        .subtract(1, 'day')
                        .format('DD.MM.YYYY') +
                      ' auschecken.'
                }`
              : 'Es befinden sich aktuell keine Personen in diesem Gelände.'}
          </Message>
          {this.warning && (
            <Message className='mt-4' color='warning'>
              {this.warning}
            </Message>
          )}
          {this.success && (
            <Message className='mt-4' color='warning'>
              {this.success}
            </Message>
          )}
          {this.residentIds.length > 0 && isReadyForCheckOut && (
            <div className='mt-4 flex justify-center'>
              <Button
                onClick={this.checkOut}
                disabled={!isReadyForCheckOut || this.loading}
                loading={this.loading}
                block
              >
                Bewohner auschecken
              </Button>
            </div>
          )}
        </div>

        <div className='mt-6 flex gap-2 justify-between'>
          <Button onClick={() => this.props.onStep('setDate')} color='secondary' outline>
            Zurück
          </Button>
          <Button onClick={this.props.onClose} disabled={this.loading}>
            Fertig
          </Button>
        </div>
      </>
    )
  }
}
