import { Collection, hermes, Resource } from '@byll/hermes'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import { InputResident } from 'components/Form/components/InputResident'
import { action, runInAction } from 'mobx'
import * as uuid from 'uuid'
import { LetterEntry } from './LetterEntry'
import { Model } from 'components/Form/Model'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { IResidentLettersFilter } from 'contracts/residents/interfaces/IResidentLettersFilter'
import { SearchCaption } from './SearchCaption'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'

interface Props {
  entries: Collection<IResidentLetterEntry, { sort: string }, IResidentLettersFilter>
}

@observer
export class ResidentsList extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model = new Model<{ residentId: string | null }>({ residentId: null })
  private readonly disposers: Disposer[] = []

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private mapResident = (res: Resource<IResidentLetterEntry>) => {
    if (!res.data) {
      return null
    }

    return <LetterEntry entry={res.data} key={res.id} />
  }

  private onChoose = (residentId: string | null, label: string | null) => {
    if (!residentId || label === null) {
      return
    }
    runInAction(() => (this.model.values.residentId = null))
    const name = label.split(', ')
    const entry: IResidentLetterEntry = {
      id: uuid.v4(),
      compoundId: this.props.entries.query.compoundId || '',
      compoundLabel: '',
      receiver: {
        id: residentId,
        imageId: null,
        sex: null,
        firstName: name[1] || '',
        lastName: name[0] || '',
        dateOfBirth: null,
      },
      room: '',
      letters: '1',
      yellowLetters: null,
      updatedAt: new Date().toISOString(),
      authorizations: [],
    }
    const available = this.props.entries.resources?.find(
      (r) => r.data?.receiver?.id === residentId,
    )
    if (available) {
      hermes.patch(`/api/${this.context.instance.id}/residentLetters/${available.id}`, {
        updatedAt: entry.updatedAt,
        letters: String(+(available.data?.letters || 0) + 1),
      })
    } else {
      const resource = new Resource<IResidentLetterEntry>(
        `/api/${this.context.instance.id}/residentLetters/${entry.id}`,
      )
      this.disposers.push(
        resource.init({
          implicit: true,
          create: { data: entry, notifySelf: false, indexPath: this.props.entries.path },
        }),
      )
      /**
       * Hermes immediate resource is not valid yet. room, imageId, sex and dateOfBirth are not available.
       * Option 1 would be to notify the resource again from the backend. But we could not ensure that the
       * backend notification arrives after the patch request returns. Even if it is fired with a delay, there
       * could be other subscribers that use up the delay time. Therefore we just update the resource afterwards.
       * This provokes a patch request that contains non-patchable data which is ignored without error in the controller.
       * -> Frontend is recent.
       */
      hermes
        .getOnceNew<IResidentSearchResult>(
          `/api/${this.context.instance.id}/residentSearchResults/${residentId}`,
          { getFromStoreIfPossible: true },
        )
        .then(
          action((resident) => {
            if (!resource.data) {
              return
            }
            resource.data.room = resident.accommodation.room?.label || '-'
            resource.data.receiver = {
              ...resource.data.receiver,
              imageId: resident.imageId,
              sex: resident.sex,
              dateOfBirth: resident.dateOfBirth,
            }
          }),
        )
    }
  }

  private getFilter = (compoundId: string): IResidentSearchResultsFilter => ({
    responsibleCompoundId: compoundId,
    responsibleScope: 'active',
    page: '0,6',
    deleted: 'no',
  })

  render() {
    if (!this.props.entries.resources) {
      return <Spinner />
    }
    return (
      <div className='flex flex-col pb-6'>
        <div className='-my-2 overflow-x-auto'>
          <div className='py-2 align-middle inline-block min-w-full'>
            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      <SearchCaption entries={this.props.entries} orderBy='receiver.name'>
                        Name
                      </SearchCaption>
                    </th>
                    <th
                      scope='col'
                      className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                    >
                      Normale Briefe
                    </th>
                    <th
                      scope='col'
                      className='max-w-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap'
                    >
                      Gelbe Briefe
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      <SearchCaption entries={this.props.entries} orderBy='room.label'>
                        Zimmer
                      </SearchCaption>
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                    >
                      Abholungsbevollmächtigte
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {this.props.entries.resources.map(this.mapResident)}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {this.props.entries.query.compoundId && (
          <div className='bg-white sm:rounded-lg mt-6 shadow p-6'>
            <InputResident
              model={this.model}
              name='residentId'
              label='Neuer Eintrag'
              placeholder='Bewohner auf Postliste setzen'
              onChoose={this.onChoose}
              filter={this.getFilter(this.props.entries.query.compoundId)}
              key={this.props.entries.query.compoundId}
            />
          </div>
        )}
      </div>
    )
  }
}
