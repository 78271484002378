import { hermes } from '@byll/hermes'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { UserCircleIcon } from '@heroicons/react/outline'
import { Callout } from 'components/Callout'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { InputDate } from 'components/Form/components/InputDate'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { CardColumn } from 'components/SideBarLayout/components/CardColumn'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { TopBar } from 'components/SideBarLayout/components/TopBar'
import { IUserDetail } from 'contracts/users/interfaces/IUserDetail'
import { UserDetailValidator } from 'contracts/users/validators/UserDetailValidator'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

const sexOptions: InputSelectOption[] = [
  { value: null, label: '' },
  { value: 'male', label: 'Herr' },
  { value: 'female', label: 'Frau' },
  { value: 'divers', label: 'Divers' },
]

interface Props {}

@observer
export class AccountProfile extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = true
  @observable private hasChanges = false
  @observable.ref private model = new Model<any>({
    sex: null,
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    phone: '',
    street: '',
    zip: '',
    city: '',
    dateOfBirth: null,
    notes: '',
    department: '',
  })
  private disposer: Disposer | null = null
  private original: any

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  componentWillUnmount() {
    this.disposer?.()
  }

  private load = async () => {
    const detail = await hermes.getOnceNew<IUserDetail>(
      `/api/${this.context.instance.id}/users/userDetails/${this.context.user.id}`,
    )
    runInAction(() => {
      this.original = {
        sex: this.context.user.sex,
        title: this.context.user.title,
        firstName: this.context.user.firstName,
        lastName: this.context.user.lastName,
        email: detail.email,
        mobile: detail.mobile,
        phone: detail.phone,
        street: detail.street,
        zip: detail.zip,
        city: detail.city,
        dateOfBirth: detail.dateOfBirth,
        notes: detail.notes,
        department: detail.department,
      }
      this.model = new Model(this.original, UserDetailValidator)
      this.loading = false
      this.disposer = reaction(
        () => toJS(this.model.values),
        () => {
          this.hasChanges = true
          this.disposer?.()
        },
      )
    })
  }

  private save = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (this.loading || !this.original) {
      return
    }
    runInAction(() => (this.loading = true))
    await hermes.patch(
      `/api/${this.context.instance.id}/users/userDetails/${this.context.user.id}`,
      {
        email: this.model.values.email,
        mobile: this.model.values.mobile,
        phone: this.model.values.phone,
        street: this.model.values.street,
        zip: this.model.values.zip,
        city: this.model.values.city,
        dateOfBirth: this.model.values.dateOfBirth,
        notes: this.model.values.notes,
        department: this.model.values.department,
      },
    )
    const updatable: any = {}
    if (this.original.sex !== this.model.values.sex) {
      updatable.sex = this.model.values.sex
    }
    if (this.original.title !== this.model.values.title) {
      updatable.title = this.model.values.title
    }
    if (Object.keys(updatable).length > 0) {
      await hermes.patch(
        `/api/${this.context.instance.id}/users/${this.context.user.id}`,
        updatable,
      )
    }
    runInAction(() => (this.loading = false))
  }

  @action
  private reset = () => {
    this.model = new Model(this.original)
    this.hasChanges = false
  }

  render() {
    return (
      <div className='md:pl-80 flex flex-col flex-1 min-h-screen'>
        <TopBar>
          <ol className='bg-white px-2 flex'>
            <li className='flex'>
              <div className='flex items-center text-gray-600'>
                <UserCircleIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
                <span className='ml-2'>{`${this.context.user.firstName} ${this.context.user.lastName}`}</span>
              </div>
            </li>
            <li className='flex'>
              <div className='flex items-center'>
                <svg
                  className='flex-shrink-0 h-5 w-5 text-gray-300'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                >
                  <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                </svg>
                Profil
              </div>
            </li>
          </ol>
        </TopBar>

        <CardColumn>
          {/* Contact data */}
          <CardColumnCard
            title='Kontakt'
            subtitle='Name, Firma und Kontaktdaten'
            saveButton='Speichern'
            onSave={this.save}
            cancelButton={this.hasChanges ? 'Änderungen verwerfen' : undefined}
            onCancel={this.reset}
          >
            <div className='grid sm:grid-cols-6 gap-5' id={this.model.id}>
              <InputSelect
                model={this.model}
                name='sex'
                options={sexOptions}
                label='Anrede'
              />
              <InputText model={this.model} name='title' label='Titel' />
              <InputText
                className='col-span-2'
                model={this.model}
                disabled
                name='firstName'
                label='Vorname'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                disabled
                name='lastName'
                label='Nachname'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                name='email'
                label='E-Mail'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                name='mobile'
                label='Mobil'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                name='phone'
                label='Festnetz'
              />
              <InputText
                className='col-span-4'
                model={this.model}
                name='street'
                label='Straße'
              />
              <InputDate
                className='col-span-2'
                model={this.model}
                name='dateOfBirth'
                label='Geburtsdatum'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                name='zip'
                label='PLZ'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                name='city'
                label='Ort'
              />
              <InputText
                className='col-span-2'
                model={this.model}
                name='notes'
                label='Notiz'
              />
              <InputTextarea
                className='col-span-6'
                model={this.model}
                name='department'
                label='Abteilung'
                rows={3}
              />
            </div>
            {this.loading && <DialogOverlaySpinner opaque />}
          </CardColumnCard>

          {/* ID card */}
          <CardColumnCard
            title='Ausweis'
            subtitle='Mitarbeiterausweis, Nutzung und Gültigkeit'
            className='min-h-[380px]'
          >
            <div className='absolute rounded-md bg-gray-100 border border-gray-300 bottom-0 right-0 left-0 top-6 flex overflow-hidden'>
              <Callout icon='fas fa-id-card' title='Kein Mitarbeiterausweis vorhanden' />
            </div>
          </CardColumnCard>
        </CardColumn>
      </div>
    )
  }
}
