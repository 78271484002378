import { z } from 'zod'
import { isPage, pageErrorMessage } from '../../general/helpers/isPage'
import { isYmdDate } from '../../general/helpers/isYmdDate'

// Attention: If you change this, you should also modify
// ../helpers/isEmptySearchResultsFilter.ts

export const ResidentSearchResultsFilterValidator = z
  .object({
    guiUrl: z.string(), // This allows the gui to specify from which view the search is requested. Some views have implicit limitations (e.g. /residents/find should only find residents in stamm buildings if permissions.menu_resident_search < 3)
    searchEmpty: z.enum(['yes']), // Set this to yes to always get results even if there is no specific filter (used for search)
    deleted: z.enum(['yes', 'no']).nullable(),
    onlyHv: z.enum(['yes', 'no']).nullable(), // only show Haushaltsvorstand.
    onlyKriegsfluchtUkr: z.enum(['yes', 'no']).nullable(), // only show Kriegsflucht Ukraine.
    page: z.string().refine(isPage, pageErrorMessage),
    sort: z.union([
      z.enum([
        'createdAt,asc',
        'createdAt,desc',
        'name,asc',
        'name,desc',
        'nationality,asc',
        'nationality,desc',
        'dateOfBirth,asc',
        'dateOfBirth,desc',
        'checkInDateDe,asc',
        'checkInDateDe,desc',
        'checkOutDateDe,asc',
        'checkOutDateDe,desc',
        'respBeginDateDe,asc',
        'respBeginDateDe,desc',
        'respEndDateDe,asc',
        'respEndDateDe,desc',
        'lastScanAt,asc',
        'lastScanAt,desc',
        'roomLabel,asc',
        'roomLabel,desc',
        'buildingLabel,asc',
        'buildingLabel,desc',
        'compoundLabel,asc',
        'compoundLabel,desc',
        'latestCostCoveragePayer,asc',
        'latestCostCoveragePayer,desc',
        'latestCostCoverageAktenzeichen,asc',
        'latestCostCoverageAktenzeichen,desc',
        'latestCostCoverageEndDate,asc',
        'latestCostCoverageEndDate,desc',
        'familyRelation,asc',
        'familyRelation,desc',
        'lfgAufenthaltsstatus,asc',
        'lfgAufenthaltsstatus,desc',
        'lfgUnterbringungsgrund,asc',
        'lfgUnterbringungsgrund,desc',
        'lfgSchutzbeduerftig,asc',
        'lfgSchutzbeduerftig,desc',
        'lfgSozialarbeiter,asc',
        'lfgSozialarbeiter,desc',
        'briefkasten,asc',
        'briefkasten,desc',
        'numberOfFamilyMembers,asc',
        'numberOfFamilyMembers,desc',
        'languagesDe,asc',
        'languagesDe,desc',
        'cityOfBirth,asc',
        'cityOfBirth,desc',
        'vulnerableGruppen,asc',
        'vulnerableGruppen,desc',
        'gateMessages,asc',
        'gateMessages,desc',
        'openTodos,asc',
        'openTodos,desc',
      ]),
      z.null(),
    ]),
    searchString: z.string().max(255),
    creationUserId: z.union([z.string().max(255), z.null()]),
    familyId: z.string().min(1).nullable(), // comma separated list of family ids (usually only one)
    familyGroupId: z.string().uuid(),
    residentIds: z.string(), // comma separated list of resident ids

    // Gebührenstatus
    billingStatus: z
      .enum(['SW/SZ/GpfE/Geplant', 'SW/SZ/GpfE/Geplant ohne Datum', 'AV fehlt'])
      .nullable(), // Awum billing status

    // Responsibility
    responsibleCompoundId: z.string().nullable(), // comma separated list of compound ids
    responsibleScope: z.enum(['active', 'inactive']).nullable(),
    lastEndOfResponsibilityBefore: z.string().refine(isYmdDate).nullable(), // last responsibility ended before this date. Used for auto archiving

    // Anwesenheit
    visit: z.enum(['present', 'absent']).nullable(),

    // In compound oder building irgendwann überschneidend mit diesem Intervall, null bedeutet +-infinity
    compoundId: z.union([z.string().min(1).max(255), z.null()]),
    buildingId: z.union([z.string().min(1).max(255), z.null()]),
    roomIds: z.array(z.string().min(1).max(255)).nullable(),
    checkedIn: z.enum(['now', 'not now', 'anytime', 'custom']),
    checkedInBegin: z.union([z.string().refine(isYmdDate), z.null()]),
    checkedInEnd: z.union([z.string().refine(isYmdDate), z.null()]),

    // Filtere nach Anrede
    sex: z.enum(['male', 'female', 'divers', 'empty']).nullable(),

    // Filtere nach Nationalität, can be comma separated list
    nationalityId: z.string().nullable(),
    languageIds: z.string(), // comma separated list of language ids

    // Suche nach Todos
    todoText: z.union([z.string().min(1).max(255), z.null()]), // Dropdown mit Standard-Todos
    todoState: z.enum(['done', 'open']), // Suche nach 'erledigt' oder 'offen'

    // Suche nach Datum
    dateType: z
      .enum([
        'dateOfBirth',
        'createdAt',
        'reponsibilityBeginDate',
        'reponsibilityEndDate',
      ])
      .nullable(),
    dateState: z.enum(['set', 'empty']),
    dateBegin: z.union([z.string().refine(isYmdDate), z.null()]),
    dateEnd: z.union([z.string().refine(isYmdDate), z.null()]),

    // Suche nach Alter (könnte man über Datum machen, aber dann ist für die UI nicht klar, welcher Filter gesetzt ist)
    ageBracket: z.string(), // e. g. '5' or '5-10'

    // Suche nach Schutzbedürftigkeit
    lfgSchutzbeduerftig: z.string(),

    // Suche nach KÜ Eigenschaften, can be comma separated list
    costCoveragePayer: z.string().nullable(),

    fields: z.union([z.string(), z.null()]), // comma separated field list to be transmitted in data object
    view: z.union([z.enum(['tile', 'list']), z.null()]), // Use those options to control ui state. This has no impact on api. Ignore those fields.
  })
  .partial()
