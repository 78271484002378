import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import dayjs from 'dayjs'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  model: Model<ITodoSearchResult>
  isAssignedToCurrentUser: boolean
}

@observer
export class TicketDialogAccept extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private acceptTicket = () => {
    this.props.model.values.gateMessage = '2-accepted'
    const assigneeIds = [`user-${this.context.user.id}`]
    const watcherIds = this.props.model.values.assigneeIds
    try {
      hermes.patch(
        `/api/${this.context.instance.id}/todoLists/${this.props.model.values.listId}/todos/${this.props.model.values.id}`,
        { assigneeIds, watcherIds },
      )
    } catch (_e) {
      alert('Bei der Annahme des Tickets ist ein Fehler aufgetreten.')
    }
  }

  render() {
    const { model } = this.props
    const isAccepted = model.values.gateMessage === '2-accepted'

    return (
      <div className='rounded-sm shadow bg-white flex relative mb-6'>
        <div className='flex-[0_0_268px] pt-11 pl-11 leading-7'>
          <div>Empfangsbestätigung</div>
          <div className='text-gray-400 text-xs'>
            Hier kann der Erhalt der
            <br />
            Aufgabe bestätigt werden.
          </div>
        </div>

        {/* Status for assignee */}
        {this.props.isAssignedToCurrentUser && (
          <div className='flex-auto pt-11 py-8 pr-6'>
            <div className='text-sm text-gray-800 leading-6 mb-4'>
              {`Diese Aufgabe wurde Ihnen von ${model.values.assigner?.firstName} ${model.values.assigner?.lastName} zugewiesen. Klicken Sie auf "Ticket annehmen", um Ihren Kollegen zu zeigen, dass Sie das Ticket bearbeiten werden.`}
            </div>

            {isAccepted && (
              <div className='flex'>
                <Button className='flex-content cursor-default' color='success' disabled>
                  <span className='mr-2'>
                    <i className='fa fa-check' />
                  </span>
                  Ticket angenommen
                </Button>
                {model.values.deliveredAt && (
                  <div className='flex-content ml-4 mr-auto leading-[38px] text-gray-500 text-sm'>
                    {`am ${dayjs(model.values.deliveredAt).format('DD.MM.YYYY')}`}
                  </div>
                )}
              </div>
            )}

            {!isAccepted && (
              <Button className='ml-auto' color='primary' onClick={this.acceptTicket}>
                Ticket annehmen
              </Button>
            )}
          </div>
        )}

        {/* Status for admin */}
        {!this.props.isAssignedToCurrentUser && (
          <div className='flex-auto pt-11 py-8 pr-6'>
            <div className='text-sm text-gray-800 leading-6 mb-4'>
              {`Diese Aufgabe wurde einzelnen Mitarbeitern oder Gruppen zugewiesen. ${
                isAccepted
                  ? ''
                  : 'Im nächsten Schritt nimmt einer der genannten Mitarbeiter diese Aufgabe an.'
              }`}
            </div>

            {isAccepted && (
              <div className='leading-6 text-gray-800 text-sm'>
                <span className='mr-2 text-indigo-500'>
                  <i className='fa fa-user' />
                </span>
                {`Angenommen von ${model.values.assignees[0]?.name}`}
                <br />

                {model.values.deliveredAt && (
                  <>
                    <span className='mr-2 text-indigo-500'>
                      <i className='fa fa-clock' />
                    </span>
                    {`Datum der Annahme: ${dayjs().format('DD.MM.YYYY')}`}
                    <br />
                  </>
                )}
              </div>
            )}

            {!isAccepted && (
              <Button className='ml-auto cursor-default' color='warning' disabled>
                <span className='mr-2'>
                  <i className='fa fa-clock' />
                </span>
                Warte auf Rückmeldung
              </Button>
            )}
          </div>
        )}
      </div>
    )
  }
}
