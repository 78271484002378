import { action, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AnnotationIcon, ExclamationIcon } from '@heroicons/react/outline'
import { AppContext, AppContextProps } from './connection/models/AppContext'
import { Wait } from 'modules/Pdf/components/Wait'

const colors = {
  primary: {
    button: 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
    icon: <AnnotationIcon className='h-6 w-6 text-indigo-600' aria-hidden='true' />,
    iconBg: 'bg-indigo-100',
  },
  danger: {
    button: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
    icon: <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />,
    iconBg: 'bg-red-100',
  },
}

const mount = observable.box<{
  close: () => void
  children: JSX.Element
  context?: AppContextProps
  width?: number
} | null>(null, { deep: false })
let open: { resolve } | null = null

function alert(
  title: string,
  body: JSX.Element | string,
  options?: {
    closable?: boolean
    color?: 'primary' | 'danger'
    confirm?: string
    cancel?: string
  },
): Promise<boolean> {
  let customResolve

  const close = action((arg: any) => {
    if (!open || open.resolve !== customResolve) {
      return
    } // Current dialog was already closed. Ignore second .close()
    customResolve?.(arg)
    open = null
    mount.set(null)
  })

  return new Promise<boolean>((resolve) => {
    // Reject open dialog
    if (open) {
      open.resolve(false)
    }
    customResolve = (val?) => resolve(val) // Wrap in new function that can be used as identity of current dialog request.
    open = { resolve: customResolve }

    runInAction(() => {
      mount.set({
        close: () => {
          if (options?.closable !== false) {
            close(false)
          }
        },
        children: (
          <>
            <div className='sm:flex sm:items-start'>
              <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${
                  colors[options?.color || 'primary'].iconBg
                }`}
              >
                {colors[options?.color || 'primary'].icon}
              </div>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
                <Dialog.Title
                  as='h3'
                  className='text-lg leading-6 font-medium text-gray-900'
                >
                  {title}
                </Dialog.Title>
                <div className='mt-2 text-sm text-gray-500'>{body}</div>
              </div>
            </div>
            <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
              <button
                type='button'
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${
                  colors[options?.color || 'primary'].button
                }`}
                onClick={() => close(true)}
              >
                {options?.confirm || 'OK'}
              </button>
              {options?.cancel && (
                <button
                  type='button'
                  className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                  onClick={() => close(false)}
                >
                  {options?.cancel}
                </button>
              )}
            </div>
          </>
        ),
      })
    })
  })
}

function custom(
  body: JSX.Element,
  options?: { closable?: boolean; context?: AppContextProps; width?: number },
): Promise<any> & { close: (arg?: any) => void } {
  let customResolve

  const close = action((arg: any) => {
    if (!open || open.resolve !== customResolve) {
      return
    } // Current dialog was already closed. Ignore second .close()
    customResolve?.(arg)
    open = null
    mount.set(null)
  })

  const promise = new Promise<any>((resolve) => {
    // Reject open dialog
    if (open) {
      open.resolve(false)
    }
    customResolve = (val?) => resolve(val) // Wrap in new function that can be used as identity of current dialog request.
    open = { resolve: customResolve }

    runInAction(() => {
      mount.set({
        close: () => {
          if (options?.closable !== false) {
            close(false)
          }
        },
        context: options?.context,
        children: body,
        width: options?.width,
      })
    })
  })

  ;(promise as any).close = close
  return promise as any
}

export const box = {
  alert: alert,
  custom: custom,
  close: action(() => {
    open?.resolve(false)
    open = null
    mount.set(null)
  }),
}

export const BoxMount: React.FC<{}> = observer(() => {
  const config = mount.get()
  if (!config) {
    return null
  }

  const jsx = (
    <Transition.Root show as={Fragment} appear>
      <Dialog
        as='div'
        className='fixed z-50 inset-0 overflow-y-auto'
        onClose={config.close}
      >
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-0'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-0'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
              style={config.width ? { width: config.width } : undefined}
            >
              {config.children}
              <Wait />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )

  if (config.context) {
    return <AppContext.Provider value={config.context}>{jsx}</AppContext.Provider>
  }
  return jsx
})
