import React from 'react'
import { DocumentTemplateProps } from '../interfaces/DocumentTemplateProps'
import { LfgbAktendeckblattBewohnerakte } from './LfgbAktendeckblattBewohnerakte'
import { LfgbChecklisteErstausstattung } from './LfgbChecklisteErstausstattung'
import { LfgbCostCoverageSignature } from './LfgbCostCoverageSignature'
import { LfgbEinwilligungDatenschutz } from './LfgbEinwilligungDatenschutz'
import { LfgbElterlicheFuersorgepflicht } from './LfgbElterlicheFuersorgepflicht'
import { LfgbEntbindungVonDerSchweigepflicht } from './LfgbEntbindungVonDerSchweigepflicht'
import { LfgbErklaerungZurueckgelasseneHabe } from './LfgbErklaerungZurueckgelasseneHabe'
import { LfgbKenntnisnahmeHausordnung2022 } from './LfgbKenntnisnahmeHausordnung2022'
import { LfgbKenntnisnahmeMasern } from './LfgbKenntnisnahmeMasern'
import { LfgbMietschuldenfreiheitsbestaetigung } from './LfgbMietschuldenfreiheitsbestaetigung'
import { LfgbSchluesselprotokoll } from './LfgbSchluesselprotokoll'
import { LfgbWohnungsgeberbestaetigung } from './LfgbWohnungsgeberbestaetigung'
import { LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldung } from './LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldung'
import { StjaAufhebungInobhutnahmeP42a } from './StjaAufhebungInobhutnahmeP42a'
import { StjaBerichtigungPersonendatenP42a } from './StjaBerichtigungPersonendatenP42a'
import { StjaUmstellungP42 } from './StjaUmstellungP42'
import { StjaVorlaeufigeInobhutnahmeP42a } from './StjaVorlaeufigeInobhutnahmeP42a'
import LfgbEinwilligungDatenschutzImage from './LfgbEinwilligungDatenschutz/thumb.webp'
import LfgbAktendeckblattBewohnerakteImage from './LfgbAktendeckblattBewohnerakte/thumb.webp'
import LfgbSchluesselprotokollImage from './LfgbSchluesselprotokoll/thumb.webp'
import LfgbWohnungsgeberbestaetigungImage from './LfgbWohnungsgeberbestaetigung/thumb.webp'
import LfgbMietschuldenfreiheitsbestaetigungImage from './LfgbMietschuldenfreiheitsbestaetigung/thumb.webp'
import LfgbKenntnisnahmeMasernImage from './LfgbKenntnisnahmeMasern/thumb.webp'
import LfgbKenntnisnahmeHausordnung2022Image from './LfgbKenntnisnahmeHausordnung2022/thumb.webp'
import LfgbErklaerungZurueckgelasseneHabeImage from './LfgbErklaerungZurueckgelasseneHabe/thumb.webp'
import LfgbChecklisteErstausstattungImage from './LfgbChecklisteErstausstattung/thumb.webp'
import LfgbCostCoverageSignatureImage from './LfgbCostCoverageSignature/thumb.webp'
import LfgbElterlicheFuersorgepflichtImage from './LfgbElterlicheFuersorgepflicht/thumb.webp'
import LfgbEntbindungVonDerSchweigepflichtImage from './LfgbEntbindungVonDerSchweigepflicht/thumb.webp'
import LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldungImage from './LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldung/thumb.webp'
import StjaVorlaeufigeInobhutnahmeP42aImage from './StjaVorlaeufigeInobhutnahmeP42a/thumb.webp'
import StjaUmstellungP42Image from './StjaUmstellungP42/thumb.webp'
import StjaAufhebungInobhutnahmeP42aImage from './StjaAufhebungInobhutnahmeP42a/thumb.webp'
import StjaBerichtigungPersonendatenP42aImage from './StjaBerichtigungPersonendatenP42a/thumb.webp'
import { AwumBescheidAVAsyl } from './AwumBescheidAVAsyl'
import AwumBescheidAVImage from './AwumBescheidAVAsyl/thumb.webp'
import { AwumNeueinzugIntern } from './AwumNeueinzugIntern'
import AwumNeueinzugInternImage from './AwumNeueinzugIntern/thumb.webp'
import { AwumBescheidAVSW } from './AwumBescheidAVSW'
import AwumBescheidAVSWImage from './AwumBescheidAVSW/thumb.webp'
import { AwumNeueinzugExtern } from './AwumNeueinzugExtern'
import AwumNeueinzugExternImage from './AwumNeueinzugExtern/thumb.webp'
import { INSTANCES } from 'helpers/instances'

export interface DocumentTemplate {
  component: React.ComponentClass<DocumentTemplateProps> | React.FC<DocumentTemplateProps>
  key: string
  label: string
  image: string
  type: 'pdf' | 'docx' | 'xlsx'
  size?: {
    width: number
    height: number
  }
  permission?: string // Only offer this document in create-document dialog if user has permission. Offer always if there is no entry with current instanceId.
  folder?: { instanceId: string; folderId: string }[] // Only offer this document in create-document dialog if selected folder matches. Offer always if there is no entry with current instanceId.
}

const t: DocumentTemplate[] = [
  {
    key: 'LfgbEinwilligungDatenschutz',
    component: LfgbEinwilligungDatenschutz,
    label: 'Einwilligung Datenschutz',
    image: LfgbEinwilligungDatenschutzImage,
    type: 'pdf',
  },
  {
    key: 'LfgbAktendeckblattBewohnerakte',
    component: LfgbAktendeckblattBewohnerakte,
    label: 'Aktendeckblatt Bewohnerakte',
    image: LfgbAktendeckblattBewohnerakteImage,
    type: 'pdf',
  },
  {
    key: 'LfgbSchluesselprotokoll',
    component: LfgbSchluesselprotokoll,
    label: 'Schlüsselprotokoll',
    image: LfgbSchluesselprotokollImage,
    type: 'pdf',
    size: {
      width: 29.7,
      height: 22,
    },
  },
  {
    key: 'LfgbWohnungsgeberbestaetigung',
    component: LfgbWohnungsgeberbestaetigung,
    label: 'Wohnungsgeber­bestätigung',
    image: LfgbWohnungsgeberbestaetigungImage,
    type: 'pdf',
  },
  {
    key: 'LfgbMietschuldenfreiheitsbestaetigung',
    component: LfgbMietschuldenfreiheitsbestaetigung,
    label: 'Mietschuldenfreiheits­bestätigung',
    image: LfgbMietschuldenfreiheitsbestaetigungImage,
    type: 'pdf',
  },
  {
    key: 'LfgbKenntnisnahmeMasern',
    component: LfgbKenntnisnahmeMasern,
    label: 'Kenntnisnahme Masern Impfpflicht',
    image: LfgbKenntnisnahmeMasernImage,
    type: 'pdf',
  },
  {
    key: 'LfgbKenntnisnahmeHausordnung2022',
    component: LfgbKenntnisnahmeHausordnung2022,
    label: 'Kenntnisnahme Haus­ordnung & Brandschutz',
    image: LfgbKenntnisnahmeHausordnung2022Image,
    type: 'pdf',
  },
  {
    key: 'LfgbErklaerungZurueckgelasseneHabe',
    component: LfgbErklaerungZurueckgelasseneHabe,
    label: 'Erklärung zurückgelassene Habe',
    image: LfgbErklaerungZurueckgelasseneHabeImage,
    type: 'pdf',
  },
  {
    key: 'LfgbChecklisteErstausstattung',
    component: LfgbChecklisteErstausstattung,
    label: 'Empfangsbestätigung Erstausstattung',
    image: LfgbChecklisteErstausstattungImage,
    type: 'pdf',
  },
  {
    key: 'LfgbCostCoverageSignature',
    component: LfgbCostCoverageSignature,
    label: 'Allgemeiner UKN',
    image: LfgbCostCoverageSignatureImage,
    type: 'pdf',
  },
  {
    key: 'LfgbElterlicheFuersorgepflicht',
    component: LfgbElterlicheFuersorgepflicht,
    label: 'Elterliche Fürsorgepflicht',
    image: LfgbElterlicheFuersorgepflichtImage,
    type: 'pdf',
  },
  {
    key: 'LfgbEntbindungVonDerSchweigepflicht',
    component: LfgbEntbindungVonDerSchweigepflicht,
    label: 'Entbindung von der Schweigepflicht',
    image: LfgbEntbindungVonDerSchweigepflichtImage,
    type: 'pdf',
  },
  {
    key: 'LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldung',
    component: LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldung,
    label: 'Wohnungsgeberbest. polizeiliche Anmeldung',
    image: LfgbWohnungsgeberbestaetigungFuerPolizeilicheAnmeldungImage,
    type: 'pdf',
  },
  {
    key: 'StjaVorlaeufigeInobhutnahmeP42a',
    component: StjaVorlaeufigeInobhutnahmeP42a,
    label: 'Vorläufige Inobhut­nahme gem. § 42a',
    image: StjaVorlaeufigeInobhutnahmeP42aImage,
    type: 'docx',
  },
  {
    key: 'StjaUmstellungP42',
    component: StjaUmstellungP42,
    label: 'Umstellung gem. § 42',
    image: StjaUmstellungP42Image,
    type: 'docx',
  },
  {
    key: 'StjaAufhebungInobhutnahmeP42a',
    component: StjaAufhebungInobhutnahmeP42a,
    label: 'Aufhebung Inobhut­nahme gem. § 42a',
    image: StjaAufhebungInobhutnahmeP42aImage,
    type: 'docx',
  },
  {
    key: 'StjaBerichtigungPersonendatenP42a',
    component: StjaBerichtigungPersonendatenP42a,
    label: 'Berichtigung Personen­daten gem. § 42a',
    image: StjaBerichtigungPersonendatenP42aImage,
    type: 'docx',
  },
  {
    key: 'AwumBescheidAVAsyl',
    component: AwumBescheidAVAsyl,
    label: 'Bescheid AV Asyl',
    image: AwumBescheidAVImage,
    type: 'docx',
    permission: 'resident_documents_create_av_asyl',
    folder: [
      { instanceId: INSTANCES.testawum, folderId: '39' },
      { instanceId: INSTANCES.awum, folderId: '4' },
    ],
  },
  {
    key: 'AwumBescheidAVSW',
    component: AwumBescheidAVSW,
    label: 'Bescheid AV SW',
    image: AwumBescheidAVSWImage,
    type: 'docx',
    permission: 'resident_documents_create_av_sw',
    folder: [
      { instanceId: INSTANCES.testawum, folderId: '39' },
      { instanceId: INSTANCES.awum, folderId: '4' },
    ],
  },
  {
    key: 'AwumNeueinzugIntern',
    component: AwumNeueinzugIntern,
    label: 'Informationsschreiben Neueinzug (intern)',
    image: AwumNeueinzugInternImage,
    type: 'docx',
    permission: 'resident_documents_create_neueinzug_intern',
    folder: [
      { instanceId: INSTANCES.testawum, folderId: '39' },
      { instanceId: INSTANCES.awum, folderId: '4' },
    ],
  },
  {
    key: 'AwumNeueinzugExtern',
    component: AwumNeueinzugExtern,
    label: 'Informationsschreiben Neueinzug (extern)',
    image: AwumNeueinzugExternImage,
    type: 'docx',
    permission: 'resident_documents_create_neueinzug_extern',
    folder: [
      { instanceId: INSTANCES.testawum, folderId: '39' },
      { instanceId: INSTANCES.awum, folderId: '4' },
    ],
  },
]

export const templates: Map<string, DocumentTemplate> = new Map(t.map((t) => [t.key, t]))
