import React from 'react'
import { ResidentCardProps } from '../interfaces/ResidentCardProps'
import { Default } from './Default'
import { Lfgb } from './Lfgb'
import { Scheckkarte } from './Scheckkarte'
import { ScheckkarteZimmerNr } from './ScheckkarteZimmerNr'
import { AnkerSchwaben } from './AnkerSchwaben'
import { Hausausweis } from './Hausausweis'
import { LEA } from './LEA'
import { LEAvorl } from './LEAvorl'
import { WhiteLabelHausausweis } from './WhiteLabelHausausweis'
import { HausausweisStandard } from './HausausweisStandard'
import { ScheckkarteBarcode } from './ScheckkarteBarcode'

export const types: { [type: string]: React.ComponentClass<ResidentCardProps> } = {
  Default,
  Lfgb,
  Scheckkarte,
  ScheckkarteZimmerNr,
  AnkerSchwaben,
  Hausausweis,
  LEA,
  LEAvorl,
  WhiteLabelHausausweis,
  HausausweisStandard,
  ScheckkarteBarcode,
}
