import { IRealTimesWithDetails } from 'contracts/workplan/interfaces/IRealTimesWithDetails'
import { IStundenzettelData } from 'contracts/workplan/interfaces/IStundenzettelData'
import * as React from 'react'
import { StundenzettelHeader } from '../parts/01StundenzettelHeader'
import { StundenzettelTitle } from '../parts/02StundenzettelTitle'
import { StundenzettelCaptions } from '../parts/03StundenzettelCaptions'
import { StundenzettelRow } from '../parts/04StundenzettelRow'
import { StundenzettelSubtotal } from '../parts/05StundenzettelSubtotal'
import { StundenzettelTotal } from '../parts/06StundenzettelTotal'
import { StundenzettelSignature } from '../parts/07StundenzettelSignature'
import styles from '../styles.module.scss'

const PAGE_PADDING_BOTTOM = 80
const PAGE_HEIGHT = 1188
const heights = {
  StundenzettelHeader: 120,
  StundenzettelTitle: 227,
  StundenzettelCaptions: 72,
  StundenzettelRow: 31,
  StundenzettelSubtotal: 31,
  StundenzettelTotal: 31,
  StundenzettelSignature: 311,
}

interface Page {
  pageNumber: number
  components: JSX.Element[]
}

interface Element {
  type: string
  height: number
  component: JSX.Element
  hours?: number
}

function getRows(
  days: { date: string; real: IRealTimesWithDetails[]; holiday: string | null }[],
): Element[] {
  const rows: Element[] = []
  let key = 0
  for (const day of days) {
    for (let i = 0; i < day.real.length; i++) {
      rows.push({
        type: 'StundenzettelRow',
        height: heights.StundenzettelRow,
        hours:
          day.real[i].shiftId < 0
            ? 0
            : day.real[i].workHoursTotal + day.real[i].onCallHoursTotal,
        component: (
          <StundenzettelRow
            key={`row-${++key}`}
            date={i === 0 ? day.date : ''}
            real={day.real[i]}
            holiday={day.holiday}
          />
        ),
      })
    }
  }
  return rows
}

export function getStundenzettelForEmployee(
  data: IStundenzettelData,
  totalNumberOfPages: number,
  instanceId: string,
): JSX.Element[] {
  const pages: Page[] = []
  const elemens: Element[] = [
    {
      type: 'StundenzettelHeader',
      height: heights.StundenzettelHeader,
      component: (
        <StundenzettelHeader
          key='StundenzettelHeader'
          data={data}
          pageNumber={1}
          totalNumberOfPages={totalNumberOfPages}
          instanceId={instanceId}
        />
      ),
    },
    {
      type: 'StundenzettelTitle',
      height: heights.StundenzettelTitle,
      component: <StundenzettelTitle key='StundenzettelTitle' data={data} />,
    },
    {
      type: 'StundenzettelCaptions',
      height: heights.StundenzettelCaptions,
      component: <StundenzettelCaptions key='StundenzettelCaptions' />,
    },
    ...getRows(data.days),
    {
      type: 'StundenzettelTotal',
      height: heights.StundenzettelTotal,
      component: <StundenzettelTotal key='StundenzettelTotal' data={data} />,
    },
    {
      type: 'StundenzettelSignature',
      height: heights.StundenzettelSignature,
      component: <StundenzettelSignature key='StundenzettelSignature' data={data} />,
    },
  ]

  let spaceLeft = PAGE_HEIGHT - PAGE_PADDING_BOTTOM
  let subtotal = 0 // hours
  let page: Page = { pageNumber: 1, components: [] }
  for (const elem of elemens) {
    if (spaceLeft - elem.height < 0) {
      // New page
      pages.push(page)
      spaceLeft = PAGE_HEIGHT - heights.StundenzettelHeader - PAGE_PADDING_BOTTOM
      page = {
        pageNumber: 1,
        components: [
          <StundenzettelHeader
            key='StundenzettelHeader'
            data={data}
            pageNumber={pages.length + 1}
            totalNumberOfPages={totalNumberOfPages}
            instanceId={instanceId}
          />,
        ],
      }
      if (elem.type === 'StundenzettelRow') {
        page.components.push(<StundenzettelCaptions key='StundenzettelCaptions' />)
        page.components.push(
          <StundenzettelSubtotal key='StundenzettelSubtotal' subtotal={subtotal} />,
        )
        spaceLeft -= heights.StundenzettelCaptions + heights.StundenzettelSubtotal
      }
    }
    spaceLeft -= elem.height
    page.components.push(elem.component)
    if (elem.type === 'StundenzettelRow') {
      subtotal += elem.hours ?? 0
    }
  }
  pages.push(page)

  return pages.map((p, i) => (
    <div className={styles.A4} key={i}>
      {p.components}
    </div>
  ))
}
