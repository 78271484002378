import { Collection, Resource } from '@byll/hermes'
import { SideBar } from 'components/SideBarLayout/components/SideBar'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { classNames } from 'helpers/classNames'
import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import image from './title.jpg'

interface Props {
  companies: Collection<ICompany>
}

export const CompaniesSidebar: React.FC<Props> = observer((props) => {
  const location = useLocation()
  const context = useContext(AppContext)
  const navigate = useNavigate()
  const currentCompoundId: string | null = location.pathname.split('/')[2] ?? null
  const [showArchive, setShowArchive] = useState(false)

  function addCompany() {
    navigate('new/overview')
  }

  function companyMapper(res: Resource<ICompany>) {
    const company: ICompany | null = res.data
    if (!company) {
      return null
    }
    return (
      <Link
        key={company.id}
        to={`${company.id}/overview`}
        className={classNames(
          company.id === currentCompoundId
            ? 'bg-gray-100 text-gray-900 border-2 border-indigo-500'
            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group relative flex items-center px-2 py-2 font-medium rounded-md truncate',
        )}
      >
        <span>{company.name || '(kein Name)'}</span>
      </Link>
    )
  }

  return (
    <SideBar>
      <img src={image} alt='' />
      <div className='flex gap-2 ml-3 my-4 text-gray-500 items-center'>
        <span className='text-xl'>Firmen</span>
        {context.permissions.companies_edit && (
          <span className='cursor-pointer' onClick={addCompany}>
            <i className='fas fa-plus' />
          </span>
        )}
      </div>
      <div
        className='pt-5 flex flex-col absolute bottom-0 left-0 right-0 overflow-y-auto'
        style={{ top: 310 }}
      >
        <nav className='flex-content px-4 pb-4 space-y-1'>
          {props.companies.resources && props.companies.resources.length === 0 && (
            <div className='ml-3 my-4 text-gray-500'>
              <span>Bisher keine Firmen angelegt.</span>
            </div>
          )}
          {props.companies.resources &&
            props.companies.resources.filter((c) => !!c.data && !c.data.archived)
              .length === 0 &&
            props.companies.resources.filter((c) => !!c.data && c.data.archived).length >
              0 && (
              <div className='ml-3 my-4 text-gray-500'>
                <span>Es besteht keine aktive Firma.</span>
              </div>
            )}
          {props.companies.resources &&
            props.companies.resources
              .filter((c) => !!c.data && !c.data.archived)
              .map(companyMapper)}
        </nav>
        {props.companies.resources &&
          props.companies.resources.filter((c) => !!c.data && c.data.archived).length >
            0 && (
            <div className='mx-3 my-4 text-gray-500 flex justify-between items-center'>
              {showArchive && <span className='text-xl'>Archiv</span>}
              {
                <span
                  className='cursor-pointer'
                  onClick={() => setShowArchive(!showArchive)}
                >
                  {showArchive ? 'Archiv ausblenden' : 'Archiv anzeigen'}
                </span>
              }
            </div>
          )}
        {showArchive &&
          props.companies.resources &&
          props.companies.resources.filter((c) => !!c.data && c.data.archived).length >
            0 && (
            <nav className='flex-content px-4 pb-4 space-y-1'>
              {props.companies.resources &&
                props.companies.resources
                  .filter((c) => !!c.data && c.data.archived)
                  .map(companyMapper)}
            </nav>
          )}
      </div>
    </SideBar>
  )
})
