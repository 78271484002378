import { hermes } from '@byll/hermes'
import { XIcon } from '@heroicons/react/outline'
import { InputResident } from 'components/Form/components/InputResident'
import { Model } from 'components/Form/Model'
import { RoundIcon } from 'components/RoundIcon'
import { Tooltip } from 'components/Tooltip'
import { IResidentLetterAuthorization } from 'contracts/residents/interfaces/IResidentLetterAuthorization'
import {
  IReceiver,
  IResidentLetterEntry,
} from 'contracts/residents/interfaces/IResidentLetterEntry'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { sleep } from 'helpers/sleep'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getResidentImageSrc } from 'modules/Residents/helpers/getResidentImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  entry: IResidentLetterEntry
  onClose: () => void
}

@observer
export class AuthorizedListDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ residentId: string | null }> = new Model({
    residentId: null,
  })

  componentDidMount() {
    sleep(300)
    document.getElementById('input-resident')?.focus()
  }

  private onChoose = async () => {
    if (!this.model.values.residentId) {
      return
    }
    const data: Partial<IResidentLetterAuthorization> = {
      compoundId: this.props.entry.compoundId,
      receiverId: this.props.entry.receiver.id,
      authorizedId: this.model.values.residentId,
    }
    await hermes.create(
      `/api/${this.context.instance.id}/residents/${this.props.entry.receiver.id}/letterAuthorizations`,
      data,
    )
    runInAction(() => (this.model.values.residentId = null))
  }

  private deleteItem = async (a: IReceiver) => {
    await hermes.delete(
      `/api/${this.context.instance.id}/residents/${
        this.props.entry.receiver.id
      }/letterAuthorizations/${this.props.entry.compoundId + '-' + a.id}`,
    )
  }

  private getFilter = (compoundId: string): IResidentSearchResultsFilter => ({
    responsibleCompoundId: compoundId,
    responsibleScope: 'active',
    page: '0,6',
    deleted: 'no',
  })

  render() {
    const authorizations = this.props.entry.authorizations
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <h3>Abholungsbevollmächtigte verwalten</h3>
        {authorizations.length === 0 && (
          <div className='my-6 text-gray-500 text-sm'>
            Es wurden keine Personen ausgewählt.
          </div>
        )}
        {authorizations.length > 0 && (
          <div className='flex flex-wrap -space-x-2 relative z-0 items-center my-6'>
            {authorizations.map((a) => (
              <div key={a.id} className='has-tooltip group'>
                <img
                  className={`relative inline-block h-10 w-10 rounded-full ring-2 ring-white`}
                  src={getResidentImageSrc(
                    this.context.instance.id,
                    a.imageId,
                    a.sex,
                    'portrait',
                  )}
                  alt=''
                />
                <RoundIcon
                  classNameContainer='hidden group-hover:block'
                  style={{ position: 'absolute', top: 5, right: 5 }}
                  icon='fas fa-trash'
                  color='danger'
                  onClick={() => this.deleteItem(a)}
                />
                <Tooltip>{`${a.firstName} ${a.lastName}`}</Tooltip>
              </div>
            ))}
          </div>
        )}
        <InputResident
          id='input-resident'
          className='mt-2'
          name='residentId'
          model={this.model}
          label='Hinzufügen'
          placeholder='Bevollmächtigten hinzufügen'
          onChoose={this.onChoose}
          filter={this.getFilter(this.props.entry.compoundId)}
          key={this.props.entry.compoundId}
        />
      </div>
    )
  }
}
