import { Model } from 'components/Form/Model'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  model: Model<any>
  name: string
  mealTypes: InputSelectOption[]
}

@observer
export class InputMealType extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private setMealType = (value: string) =>
    (this.props.model.values[this.props.name] = value)

  render() {
    return (
      <div className='flex-content px-4 flex gap-2 -my-2 flex-wrap'>
        {this.props.mealTypes.map((type, i) => (
          <span
            key={i}
            onClick={() => this.setMealType(type.value)}
            className={`cursor-pointer inline-flex items-center rounded-md px-4 py-2 text-sm font-medium border ${
              type.value === this.props.model.values[this.props.name]
                ? 'bg-indigo-100 text-indigo-500 border-indigo-500'
                : 'text-gray-600  border-gray-300 hover:text-black'
            }`}
          >
            {type.label}
          </span>
        ))}
      </div>
    )
  }
}
