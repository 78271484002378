export const INSTANCES = {
  dev: '0',
  app: '1',
  testapp: '2',
  stja: '3',
  teststja: '4',
  awum: '5',
  testawum: '6',
  lfgb: '7',
  testlfgb: '8',
  test: '9',
}
