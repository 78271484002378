import * as React from 'react'
import { Model } from '../../Model'
import { classNames } from 'helpers/classNames'
import { Button } from '../Button'
import { Tooltip } from 'components/Tooltip'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'

export interface InputButtonGroupOption {
  value: string
  label: JSX.Element | string
  tooltip?: string
  width?: number
}

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  options: InputButtonGroupOption[] // options that can be chosen by this input
  className?: string
  children?: Element
}

@observer
export class InputButtonGroup extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action setValue = (value: string) => (this.props.model.values[this.props.name] = value)

  render() {
    return (
      <div className={classNames('relative flex', this.props.className)}>
        {this.props.options.map((option, i) => {
          const active = this.props.model.values[this.props.name] === option.value
          let radius: string = '6px'
          if (this.props.options.length > 1) {
            if (i === 0) {
              radius = '6px 0 0 6px'
            } else if (i === this.props.options.length - 1) {
              radius = '0 6px 6px 0'
            } else {
              radius = '0'
            }
          }
          return (
            <Button
              key={option.value}
              color={active ? 'primary' : 'secondary'}
              outline={!active}
              type='button'
              className='has-tooltip flex-auto border-r border-transparent hover:border-indigo-700'
              onClick={() => this.setValue(option.value)}
              style={{
                width: option.width,
                borderRadius: radius,
                padding: option.width ? '8px 0' : '8px 12px',
                borderLeft: i === 0 ? undefined : 'none',
              }}
            >
              {option.tooltip && <Tooltip position='top'>{option.tooltip}</Tooltip>}
              {option.label}
            </Button>
          )
        })}
      </div>
    )
  }
}
